<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>

<router-outlet></router-outlet>

<as-split direction="horizontal" class="eq-hierarchy-split-container">
  <as-split-area [size]="'*'">
    <div class="position-relative">
      @if (hierarchyData && hierarchyData[0]?.children?.length < 1 && displayImportBtn) {
        <h2 class="mt-5">{{ 'CONFIGURATION.STR_NO_RECORDS_FOUND' | translate }}</h2>
      }

      <button
        odxButton
        variant="secondary"
        class="import-eq-hierarchy-btn"
        data-test-id="configuration.import_eq_hierarchy_btn"
        *ngIf="displayImportBtn && !(isLoading | async)"
        (click)="openImportEquipmentHierarchyModal()"
      >
        {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY' | translate }}
      </button>
    </div>

    @if (!displayImportBtn) {
      <ignis-eq-hierarchy-filter
        [hierarchyData]="hierarchyData"
        (emitFilteredHierarchyData)="displayFiteredData($event)"
      ></ignis-eq-hierarchy-filter>
    }

    <div class="d-flex flex-column">
      <div class="tree-wrapp">
        @if (hierarchyData[0]?.children?.length > 0 && !displayImportBtn) {
          <div class="hierarchy-header">
            <h2 class="mb-3">{{ 'CONFIGURATION.STR_CATEGORY' | translate }}</h2>
            @if (displayTypeLabel) {
              <h2 class="mb-3">{{ 'CONFIGURATION.STR_TYPE' | translate }}</h2>
            }
            @if (displayModelLabel) {
              <h2 class="mb-3">{{ 'CONFIGURATION.STR_MODEL' | translate }}</h2>
            }
          </div>
        }

        <p-tree
          class="configuration-tree equipment-tree"
          [ngClass]="currentAppTheme"
          [value]="hierarchyData"
          data-test-id="equipment_hierarchy.tree"
          selectionMode="single"
          layout="horizontal"
          [(selection)]="selectedTreeNode"
          (onNodeSelect)="nodeSelect($event); toggleTreeHeader()"
          (onNodeUnselect)="nodeUnselect($event); toggleTreeHeader()"
        >
          <ng-template let-node pTemplate="default">
            <span class="p-tree-toggler" [ngClass]="{ 'tree-toggle-disabled': node.children?.length < 1 }">
              <odx-icon [iconSet]="'core'" [name]="node.expanded ? 'chevron-down' : 'chevron-right'"></odx-icon>
            </span>

            <span
              [title]="node.label"
              class="node-label"
              [ngClass]="{
                'active-node-border': node.borderHighlighted || node.highlighted,
                'active-node':
                  node?.data?.aggregateId === selectedTreeNode?.data?.aggregateId &&
                  node?.data?.typeId === selectedTreeNode?.data?.typeId &&
                  node?.data?.modelId === selectedTreeNode?.data?.modelId,
              }"
              >{{ node.label }}</span
            >
          </ng-template>
        </p-tree>
      </div>
    </div>
  </as-split-area>
  <as-split-area [size]="100 / 3" [minSize]="100 / 3" class="side-panel-area">
    <ignis-side-panel>
      @if (!selectedTreeNode && !openCreateMode) {
        <ignis-eq-hierarchy-thumbnail></ignis-eq-hierarchy-thumbnail>
      } @else {
        <ignis-create-update-eq-hierarchy-item
          [itemUrl]="itemUrl"
          [selectedTreeNode]="selectedTreeNode"
          [createMode]="openCreateMode"
          [equipmentConfigType]="equipmentConfigType"
          [isSavedButtonPressed]="isSavedButtonPressed"
          (resetSubmitButtonState)="isSavedButtonPressed = $event"
          (resetCreateModeStateAfterSave)="selectedTreeNode = null; openCreateMode = false"
          (handleConfigurationForm)="getConfigurationForm($event)"
        ></ignis-create-update-eq-hierarchy-item>
      }
    </ignis-side-panel>
  </as-split-area>
</as-split>

<footer class="blue-bar">
  <ignis-equipment-hierarchy-view-footer
    [disableAddBtn]="disableAddBtn"
    [selectedItem]="selectedTreeNode"
    [isModalOpened]="openConfirmationDeleteDialog"
    [form]="configurationForm"
    (handleCreate)="createNewConfiguration()"
    (handleSubmit)="saveItem()"
    (handleDelete)="openDeleteEquipmentHierarchyDialog()"
    (handleCopyNode)="copyNode()"
  >
  </ignis-equipment-hierarchy-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading | async"
  (handleDeleteModal)="closeDeleteEquipmentHierarchyDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_CONFIGURATION.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    <div [ngSwitch]="equipmentConfigType">
      <span *ngSwitchCase="configType.CATEGORY">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_CATEGORY' | translate }}
      </span>
      <span *ngSwitchCase="configType.TYPE">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_TYPE' | translate }}
      </span>
      <span *ngSwitchCase="configType.MODEL">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_MODEL' | translate }}
      </span>
    </div>
  </div>
</ignis-delete-confirmation-modal>

<ignis-confirmation-modal
  [isOpen]="openConfirmationChangeUnsavedNodeDialog"
  (handleConfirmationModal)="handleUnsavedChangesResponse($event)"
  class="is-closed-modal"
>
  <div slot="content">
    <div [ngSwitch]="equipmentConfigType">
      <span *ngSwitchCase="configType.CATEGORY" [attr.data-test-id]="configType.CATEGORY">
        {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_FOR_CATEGORY' | translate }}
      </span>
      <span *ngSwitchCase="configType.TYPE" [attr.data-test-id]="configType.TYPE">
        {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_FOR_TYPE' | translate }}
      </span>
      <span *ngSwitchCase="configType.MODEL" [attr.data-test-id]="configType.MODEL">
        {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_FOR_MODEL' | translate }}
      </span>
    </div>
  </div>
</ignis-confirmation-modal>

<ignis-import-equipment-hierarchy-modal
  [isOpen]="openImportEQHierarchyModal"
  (closeModal)="closeImportEquipmentHierarchyModal($event)"
></ignis-import-equipment-hierarchy-modal>
