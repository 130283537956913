import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { uniq } from 'lodash-es';
import { filter, take, takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiItem, NotificationsService, UploadFilesService } from 'src/app/common';
import { CommonConstants, NotificationConstants } from 'src/app/common/constants';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { IEquipmentConfiguration } from 'src/app/configuration/models';
import { selectedEquipmentConfiguration } from 'src/app/configuration/state/selectors/configuration.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ignis-eq-hierarchy-upload-image-control',
  templateUrl: './eq-hierarchy-upload-image-control.component.html',
  styleUrls: ['./eq-hierarchy-upload-image-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EqHierarchyUploadImageControlComponent extends OnDestroyMixin() implements OnChanges, OnInit, OnDestroy {
  @Input() configurationForm: FormGroup;
  @Input() createMode: boolean;

  @Output() emitUploadedFiles: EventEmitter<string[]> = new EventEmitter<string[]>();

  images: Array<{ name?: string; source?: string }> = [];
  uploadedImages: File[] = [];
  acceptedFormats: string[] = ['.png', '.jpg'];
  uploadFilesSuccess: boolean[] = [];
  imagesToBeDownloaded: string[] = [];
  isDownloadInProgress: boolean = false;
  maxFiles: number = 4;
  imageContentIsClicked: boolean = false;
  deviceConnectionBaseUrl: string = environment.API_URLS.DEVICE_CONNECTION;
  headers: HttpHeaders = new HttpHeaders({
    Authorization: `Bearer ${sessionStorage.getItem(CommonConstants.ACCESS_TOKEN)}`,
  });

  isImageModalOpened: boolean = false;
  selectedImage: string;
  isImagesGalleryDisplayed: boolean = false;

  isConfirmDeleteImageModalOpened: boolean = false;
  fileToBeDeleted: { imageName: string; index: number };

  notificationsService: NotificationsService = inject(NotificationsService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  uploadFileService: UploadFilesService = inject(UploadFilesService);
  httpClient: HttpClient = inject(HttpClient);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);

  ngOnChanges(): void {
    this.toggleUploadImageControl();
  }

  ngOnInit(): void {
    this.readEquipmentConfigurationDataInOrderToUpdateImageControlState();
  }

  readEquipmentConfigurationDataInOrderToUpdateImageControlState(): void {
    this.store
      .pipe(
        select(selectedEquipmentConfiguration),
        filter((response: IStoreApiItem<IEquipmentConfiguration>) => response.data !== null && !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IEquipmentConfiguration>) => {
        this.isImagesGalleryDisplayed = false;
        this.imagesToBeDownloaded = [];
        this.images = [];
        this.isDownloadInProgress = response.data.mediaLinks.length > 0;

        this.isImagesGalleryDisplayed = true;
        this.imagesToBeDownloaded = response.data.mediaLinks;

        this.imagesToBeDownloaded?.forEach((name: string) => {
          this.loadImagesFromBE(name, this.deviceConnectionBaseUrl, this.headers);
        });

        this.cdr.detectChanges();
      });
  }

  toggleUploadImageControl(): void {
    if (this.createMode) {
      this.imagesToBeDownloaded = [];
      this.images = [];
      this.isDownloadInProgress = false;
      this.configurationForm.get('mediaLinks').setValue(null);
    }
  }

  onFileChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.files) {
      Array.from(target.files).forEach((file: File) => {
        if (this.validateFile(file)) {
          const reader: FileReader = new FileReader();

          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target && typeof e.target.result === 'string') {
              this.uploadImages(Array.from(target.files), e.target.result);
            }

            target.value = '';
            this.cdr.detectChanges();
          };

          reader.readAsDataURL(file);
        }
      });
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();

    const files: FileList = event.dataTransfer?.files;

    if (files) {
      Array.from(files).forEach((file: File) => {
        if (this.validateFile(file)) {
          const reader: FileReader = new FileReader();

          reader.onload = (e: ProgressEvent<FileReader>) => {
            const result: string | ArrayBuffer = e.target?.result;

            if (typeof result === 'string') {
              this.uploadImages([file], result);
            }

            this.cdr.detectChanges();
          };

          reader.readAsDataURL(file);
        }
      });
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  validateFile(file: File): boolean {
    const validFormats: string[] = ['image/jpeg', 'image/png'];
    const maxSize: number = 5 * 1024 * 1024;

    if (!validFormats.includes(file.type)) {
      this.errorHandler(ConfigurationNotificationConstants.notificationCodes.UPLOAD_IMAGE_FORMAT_ERROR);

      return false;
    }

    if (file.size > maxSize) {
      this.errorHandler(ConfigurationNotificationConstants.notificationCodes.UPLOAD_IMAGE_SIZE_ERROR);

      return false;
    }

    return true;
  }

  uploadImages(files: File[], source: string): void {
    files.forEach((file: File, index: number) => {
      this.uploadFileService
        .uploadFiles(file)
        .pipe(take(1))
        .subscribe({
          next: (response: HttpResponse<File>) => {
            const mediaUrl: string[] = response.headers?.get('Location').split('/');
            const filename: string = mediaUrl.pop();

            Object.defineProperty(file, 'name', {
              writable: true,
              value: filename,
            });

            this.images.push({ name: filename, source });

            this.handleUploadSuccess(files);
            this.cdr.detectChanges();
          },
          error: () => {
            files.splice(index, 1);
            this.errorHandler(NotificationConstants.commonCodes.UPLOAD_FILE_FAILED);
            this.cdr.detectChanges();
          },
        });
    });
  }

  handleUploadSuccess(files: File[]): void {
    this.uploadFilesSuccess.push(true);

    if (this.uploadFilesSuccess.length === files.length) {
      this.uploadedImages.push(...files);

      this.mergeUploadedAndOldUploadedImagesAndEmit();

      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.SUCCESS,
        NotificationConstants.commonCodes.UPLOAD_FILE_SUCCESS,
        NotificationConstants.commonCodes,
      );

      this.uploadFilesSuccess = [];
    }
  }

  removeUploadedFile(event: boolean): void {
    this.isConfirmDeleteImageModalOpened = event;

    if (event) {
      this.uploadFileService.deleteSelectedFile(this.fileToBeDeleted.imageName).subscribe({
        next: () => {
          this.images.splice(this.fileToBeDeleted.index, 1);
          this.uploadedImages = this.uploadedImages.filter(
            (file: File) => file.name !== this.fileToBeDeleted.imageName,
          );

          this.mergeUploadedAndOldUploadedImagesAndEmit();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            NotificationConstants.commonCodes.DELETE_FILE_SUCCESS,
            NotificationConstants.commonCodes,
          );

          this.fileToBeDeleted = null;

          this.cdr.detectChanges();
        },
        error: () => {
          this.errorHandler(NotificationConstants.commonCodes.DELETE_FILE_FAILED);
        },
      });
    }
  }

  mergeUploadedAndOldUploadedImagesAndEmit(): void {
    const filesName: string[] = [];

    this.uploadedImages.forEach((file: File) => {
      filesName.push(file.name);
    });

    this.images.forEach((file: { name?: string; source?: string }) => {
      filesName.push(file.name);
    });

    this.emitUploadedFiles.emit(uniq(filesName));
  }

  errorHandler(message: INotificationMessage): void {
    this.notificationsService.requestShowNotification(CommonConstants.notificationType.ERROR, message, {
      ...NotificationConstants.commonCodes,
      ...ConfigurationNotificationConstants.notificationCodes,
    });

    this.cdr.detectChanges();
  }

  disableUploadOnImageClick(): void {
    this.imageContentIsClicked = true;

    setTimeout(() => {
      this.imageContentIsClicked = false;
    }, 0);
  }

  loadImagesFromBE(imageFileName: string, deviceConnectionBaseUrl: string, headers: HttpHeaders): void {
    this.isDownloadInProgress = true;
    this.httpClient
      .get(`${deviceConnectionBaseUrl}/files/${imageFileName}`, { headers: headers, responseType: 'blob' })
      .subscribe((response: Blob) => {
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
          this.processLoadedImagesData(reader.result as string, imageFileName);
        };

        reader.readAsDataURL(response);
      });
  }

  processLoadedImagesData(source: string, name: string): void {
    if (!this.createMode) {
      this.images.push({ source, name });
    } else {
      this.images = [];
    }

    if (this.images.length === this.imagesToBeDownloaded.length) {
      this.isDownloadInProgress = false;

      this.cdr.detectChanges();
    }

    this.cdr.detectChanges();
  }

  openImageModal(image: string): void {
    this.isImageModalOpened = true;
    this.selectedImage = image;
  }

  closeMediaImageModal(): void {
    this.isImageModalOpened = false;
  }

  deleteImage(imageName: string, index: number): void {
    this.fileToBeDeleted = { imageName, index };
    this.isConfirmDeleteImageModalOpened = true;
  }
}
