import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, TitleStrategy } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CardModule } from '@odx/angular/components/card';
import { HeaderModule } from '@odx/angular/components/header';
import { InlineMessageComponent } from '@odx/angular/components/inline-message';
import { LinkDirective } from '@odx/angular/components/link';
import { MainMenuModule } from '@odx/angular/components/main-menu';
import { MenuModule } from '@odx/angular/components/menu';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import * as Sentry from '@sentry/angular-ivy';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import { CountdownModule } from 'ngx-countdown';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BAWearerModule, DeactivateBAWearerGuard } from 'src/app/ba-wearer';
import { BaWearerModuleGuard } from 'src/app/ba-wearer/modules/guards/ba-wearer/ba-wearer.module.guard';
import {
  AppHttpInterceptor,
  authConfig,
  CommonModule,
  HttpHelper,
  IApplicationState,
  LoggerService,
  notificationReducer,
  WindowService,
} from 'src/app/common';
import { tableExportReducer } from 'src/app/common/state/export/reducers/export.reducers';
import { EquipmentConfigurationService } from 'src/app/configuration';
import {
  DeactivateCreateUpdateAddressBookGuard,
  DeactivateCreateUpdateChecklistGuard,
  DeactivateCreateUpdateLocationHierarchyGuard,
  DeactivateCreateUpdateServiceGuard,
  DeactivateEquipmentHierarchyComponentGuard,
  DeactivateOrganizationSettingsGuard,
} from 'src/app/configuration/modules/can-deactivate.guard';
import { ConfigurationModule } from 'src/app/configuration/modules/configuration.module';
import { ConfigurationModuleGuard } from 'src/app/configuration/modules/configuration.module.guard';
import { LocationEquipmentAddressBookTabGuard } from 'src/app/configuration/modules/location_equipment-address_book-tab.guard';
import { OrganizationSettingsTabGuard } from 'src/app/configuration/modules/organization_settings-tab.guard';
import { DeviceConnectionManagementModule, DeviceConnectionService } from 'src/app/device-connection';
import { DeviceConnectionModuleGuard } from 'src/app/device-connection/modules/device-connection.module.guard';
import { LogbookModule, LogbookService } from 'src/app/logbook';
import { LogbookModuleGuard } from 'src/app/logbook/modules/logbook.module.guard';
import { RemoteMonitoringModule, RemoteMonitoringService } from 'src/app/remote-monitoring';
import { RemoteMonitoringModuleGuard } from 'src/app/remote-monitoring/modules/remote-monitoring.module.guard';
import { IncidentService, ReportCenterModule } from 'src/app/report-center';
import { ReportCenterModuleGuard } from 'src/app/report-center/modules/report-center.module.guard';
import { RoleManagementModuleGuard } from 'src/app/role-management/modules/role-management.module.guard';
import { SettingsEffects, SettingsModule, settingsReducer, SettingsService } from 'src/app/settings';
import { UserManagementModule, UserService } from 'src/app/user-management';
import { DeactivateUserManagementGuard } from 'src/app/user-management/modules/can-deactivate.guard';
import { UserManagementModuleGuard } from 'src/app/user-management/modules/user-management.module.guard';
import { CanActivateBachChecklistGuard, DeactivateGuard, WorkshopModule, WorkshopService } from 'src/app/workshop';
import {
  CompleteBatchChecklistDeactivateGuard,
  CompleteChecklistDeactivateGuard,
} from 'src/app/workshop/modules/guards/can-deactivate/can-deactivate.guard';
import { WorkshopFeatureToggleGuard } from 'src/app/workshop/modules/guards/workshop-feature-toggle/workshop-feature-toggle.guard';
import { WorkshopTestResultsModuleGuard } from 'src/app/workshop/modules/guards/workshop-test-results/equipment-test-results.module.guard';
import { WorkshopWorkflowModuleGuard } from 'src/app/workshop/modules/guards/workshop-workflow/workshop-workflow.module.guard';
import { WorkshopModuleGuard } from 'src/app/workshop/modules/guards/workshop/workshop.module.guard';
import { RAMConnectorErrorsService } from 'src/app/workshop/services/ram-connector-errors.service';
import { environment } from 'src/environments/environment';
import { DeactivateDeviceConnectionGuard } from '../../device-connection/modules/can-deactivate.guard';
import { DeactivateEventMarkerGuard, DeactivateIncidentGuard } from '../../report-center/modules/can-deactivate.guard';
import { WorkshopTestingModuleGuard } from '../../workshop/modules/guards/workshop-testing/workshop-testing.module.guard';
import { AlarmNotificationComponent } from '../components/alarms/alarm-notification/alarm-notification.component';
import { AlarmsListComponent } from '../components/alarms/alarms-list/alarms-list.component';
import { AlarmsComponent } from '../components/alarms/alarms.component';
import { AppModuleTilesComponent } from '../components/app-module-tiles/app-module-tiles.component';
import { ExportInProgressComponent } from '../components/export-in-progress/export-in-progress.component';
import { GenericErrorPageComponent } from '../components/generic-error-page/generic-error-page.component';
import { LegalTermsPageComponent } from '../components/legal-terms-page/legal-terms-page.component';
import { LoginCallbackComponent } from '../components/login-callback/login-callback.component';
import { LicenseValidationComponent } from '../components/unauthorized-page/license-validation/license-validation.component';
import { UnauthorizedPageComponent } from '../components/unauthorized-page/unauthorized-page.component';
import { WrongSystemDateErrorPageComponent } from '../components/wrong-system-date-error-page/wrong-system-date-error-page.component';
import { GlobalErrorHandler } from '../services';
import { AccessControlItemsService } from '../services/access-control-items/access-control-items.service';
import { AccessControlService } from '../services/access-control/access-control.service';
import { LegalTermsService } from '../services/legal-terms/legal-terms.service';
import { ProductService } from '../services/product/product.service';
import { AccessControlItemsEffects } from '../state/access-control-items/effects/access-control-items.effects';
import { accessControlItemsReducer } from '../state/access-control-items/reducers/access-control-items.reducer';
import { alarmsReducer } from '../state/alarms/reducers/alarms.reducer';
import { featuresToggleReducer } from '../state/features-toggle/reducers/features-toggle.reducer';
import { LegalTermsEffects } from '../state/legal-terms/effects/legal-terms.effects';
import { legalTermsReducer } from '../state/legal-terms/reducers/legal-terms.reducer';
import { LicensesEffects } from '../state/licenses/effects/licenses.effects';
import { licensesReducer } from '../state/licenses/reducers/licenses.reducer';
import { MaintenanceModeEffects } from '../state/maintenance-mode/effects/maintenance-mode.effects';
import { maintenanceModeReducer } from '../state/maintenance-mode/reducers/maintenance-mode.reducer';
import { ProductEffects } from '../state/product/effects/product.effects';
import { productReducer } from '../state/product/reducers/product.reducer';
import { AppComponent } from './../components/app/app.component';
import { ContainerComponent } from './../components/container/container.component';
import { DashboardComponent } from './../components/dashboard/dashboard.component';
import { MaintenancePageComponent } from './../components/maintenance-page/maintenance-page.component';
import { NavbarComponent } from './../components/navbar/navbar.component';
import { SidebarComponent } from './../components/sidebar/sidebar.component';
import { AlarmsService } from './../services/alarms/alarms.service';
import { MaintenanceModeService } from './../services/maintenance-mode/maintenance-mode.service';
import { AlarmsEffects } from './../state/alarms/effects/alarms.effects';
import { AppRoutingModule, TemplatePageTitleStrategy } from './app-routing.module';
import { ErrorHandlerModule } from './error-handler.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginCallbackComponent,
    NavbarComponent,
    SidebarComponent,
    ContainerComponent,
    DashboardComponent,
    AppModuleTilesComponent,
    MaintenancePageComponent,
    AlarmsComponent,
    AlarmsListComponent,
    AlarmNotificationComponent,
    UnauthorizedPageComponent,
    LicenseValidationComponent,
    LegalTermsPageComponent,
    GenericErrorPageComponent,
    WrongSystemDateErrorPageComponent,
    ExportInProgressComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    SettingsModule,
    ConfigurationModule,
    WorkshopModule,
    LogbookModule,
    ReportCenterModule,
    UserManagementModule,
    RemoteMonitoringModule,
    DeviceConnectionManagementModule,
    BAWearerModule,
    BrowserAnimationsModule,
    CountdownModule,
    ClickOutsideModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreModule.forRoot<IApplicationState, Action>({
      router: routerReducer,
      notification: notificationReducer,
      exportTable: tableExportReducer,
      settings: settingsReducer,
      featuresToggle: featuresToggleReducer,
      product: productReducer,
      alarms: alarmsReducer,
      legalTerms: legalTermsReducer,
      maintenance: maintenanceModeReducer,
      licenses: licensesReducer,
      accessControlItems: accessControlItemsReducer,
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    EffectsModule.forRoot([
      SettingsEffects,
      AlarmsEffects,
      LegalTermsEffects,
      MaintenanceModeEffects,
      LicensesEffects,
      ProductEffects,
      AccessControlItemsEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: environment.production }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(
            http,
            '/assets/i18n/',
            `.json?v=${environment.API_URLS.SPRINT_NUMBER}-${environment.API_URLS.HOTFIX_NUMBER}`,
          );
        },
        deps: [HttpClient],
      },
    }),
    ErrorHandlerModule,
    ClickOutsideModule,
    SimplebarAngularModule,
    OktaAuthModule,
    MainMenuModule,
    HeaderModule,
    MenuModule,
    CardModule,
    InlineMessageComponent,
    LinkDirective,
  ],
  providers: [
    DeactivateGuard,
    DeactivateEquipmentHierarchyComponentGuard,
    DeactivateDeviceConnectionGuard,
    DeactivateIncidentGuard,
    DeactivateEventMarkerGuard,
    DeactivateUserManagementGuard,
    WorkshopTestingModuleGuard,
    WorkshopTestResultsModuleGuard,
    WorkshopFeatureToggleGuard,
    WorkshopWorkflowModuleGuard,
    LoggerService,
    LogbookService,
    UserService,
    IncidentService,
    HttpHelper,
    WorkshopService,
    RAMConnectorErrorsService,
    SettingsService,
    AccessControlService,
    ProductService,
    MaintenanceModeService,
    DeviceConnectionService,
    AlarmsService,
    RemoteMonitoringService,
    WindowService,
    AccessControlItemsService,
    LegalTermsService,
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    {
      provide: OKTA_CONFIG,
      useFactory: authConfig.OktaAuthFactory,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: /* istanbul ignore next */ () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    DeviceConnectionModuleGuard,
    ReportCenterModuleGuard,
    WorkshopModuleGuard,
    BaWearerModuleGuard,
    LogbookModuleGuard,
    RemoteMonitoringModuleGuard,
    UserManagementModuleGuard,
    RoleManagementModuleGuard,
    ConfigurationModuleGuard,
    DeactivateCreateUpdateServiceGuard,
    EquipmentConfigurationService,
    DeactivateCreateUpdateLocationHierarchyGuard,
    DeactivateCreateUpdateChecklistGuard,
    DeactivateCreateUpdateAddressBookGuard,
    DeactivateOrganizationSettingsGuard,
    DeactivateBAWearerGuard,
    LocationEquipmentAddressBookTabGuard,
    OrganizationSettingsTabGuard,
    CompleteBatchChecklistDeactivateGuard,
    CompleteChecklistDeactivateGuard,
    CanActivateBachChecklistGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
