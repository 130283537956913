<ng-template odxModal #addChecklistModal let-modal="close" #createUpdateChecklist>
  <odx-modal-header>
    @if (router.url?.includes(ConfigurationModuleRoutes.createChecklist)) {
      <odx-area-header>
        {{ 'CONFIGURATION_CHECKLIST.STR_CREATE_CHECKLIST' | translate }}
      </odx-area-header>
    } @else {
      <odx-area-header>
        {{ 'CONFIGURATION_CHECKLIST.STR_EDIT_CHECKLIST' | translate }}
      </odx-area-header>
    }
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }" id="create-update-checklist-content">
    @if (isLoading) {
      <div [odxLoadingSpinner]="isLoading === true" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
    } @else {
      <div>
        <ignis-checklist-form
          [form]="checklistForm"
          [checklistItems]="checklistItems"
          [(httpCustomErrorCode)]="httpCustomErrorCode"
          (httpCustomErrorCodeChange)="httpCustomErrorCode = $event"
          (addParameterGroup)="addParameter($event)"
          (onSubmit)="onSubmit()"
        >
        </ignis-checklist-form>
      </div>
    }

    <button odxButton (click)="addParameter()" type="button" data-test-id="configuration_checklist.add_parameter_btn">
      <odx-icon name="add" size="inline" alignLeft></odx-icon>
      {{ 'CONFIGURATION_CHECKLIST.STR_ADD_PARAMETER_BTN' | translate }}
    </button>
  </odx-modal-content>
  <odx-modal-footer>
    <button
      odxButton
      variant="danger"
      class="delete-checklist"
      (click)="confirmDeleteModalOpen()"
      type="submit"
      *ngIf="router.url?.includes(ConfigurationModuleRoutes.updateChecklist)"
      data-test-id="configuration.delete_checklist_btn"
    >
      {{ 'CONFIGURATION.STR_DELETE_CHECKLIST_BTN' | translate }}
    </button>

    <button
      odxButton
      class="cancel-checklist"
      (click)="closeModal()"
      variant="secondary"
      data-test-id="modal_actions.cancel_btn"
    >
      {{ 'CONFIGURATION.STR_CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      (click)="onSubmit()"
      [disabled]="!checklistForm.dirty || checklistForm.invalid || (isSubmitting | async)"
      type="submit"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'CONFIGURATION.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="$event ? navigateBackOpen() : navigateBackClosed()"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CHECKLIST' | translate }}
  </div>
</ignis-confirmation-modal>

<ignis-delete-confirmation-modal
  [isOpen]="isConfirmDeleteModalOpen"
  [isLoading]="false"
  (handleDeleteModal)="onDeleteChecklist($event)"
>
  <div slot="header">
    {{ 'CONFIGURATION.STR_DELETE_CHECKLIST_HEADER' | translate }}
  </div>
  <div slot="content">
    {{ 'CONFIGURATION.STR_DELETE_CHECKLIST_CONTENT' | translate }}
  </div>
</ignis-delete-confirmation-modal>
