import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, delay, filter, map, Observable, of, take, takeUntil } from 'rxjs';
import {
  CommonConstants,
  IApplicationState,
  INotificationConstant,
  IStoreApiItem,
  NotificationsService,
} from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ConfigurationConstants, ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import {
  IAddressBookPage,
  ICustomTreeNode,
  IEditEquipmentHierarchy,
  IEquipmentConfigType,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
  IUpdateTaskChildren,
} from 'src/app/configuration/models';
import { EquipmentConfigActions, EquipmentHierarchyActions, TasksActions } from 'src/app/configuration/state/actions';
import {
  selectAddedEquipmentHierarchy,
  selectAddressBookPage,
  selectedEquipmentConfiguration,
  selectEditedEquipmentHierarchy,
  selectUpdatedTaskChildren,
} from 'src/app/configuration/state/selectors/configuration.selectors';
import { EquipmentNotificationConstants } from 'src/app/workshop';
import { configurationForm } from './form-fields';

@Component({
  selector: 'ignis-create-update-eq-hierarchy-item',
  templateUrl: './create-update-eq-hierarchy-item.component.html',
  styleUrl: './create-update-eq-hierarchy-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdateEqHierarchyItemComponent extends OnDestroyMixin() implements OnChanges, OnInit {
  @Input() itemUrl: string;
  @Input() selectedTreeNode: ICustomTreeNode<undefined>;
  @Input() createMode: boolean = false;
  @Input() equipmentConfigType: string;
  @Input() isSavedButtonPressed: boolean;

  @Output() handleConfigurationForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() resetSubmitButtonState: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resetCreateModeStateAfterSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: Observable<boolean>;
  selectedConfigurationItem: IEquipmentConfiguration;
  configurationForm: FormGroup = null;
  httpCustomErrorCode: string | INotificationMessage;
  addedType: string;

  configType: IEquipmentConfigType = ConfigurationConstants.EquipmentConfigType;

  tasksActions: TasksActions = inject(TasksActions);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  equipmentHierarchyActions: EquipmentHierarchyActions = inject(EquipmentHierarchyActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  router: Router = inject(Router);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  storage: StorageMap = inject(StorageMap);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();

    this.configurationForm = new FormGroup({ ...configurationForm });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.openFormInCreateMode(changes.createMode?.currentValue as boolean);
    this.requestEquipmentConfigurationData(changes.itemUrl?.currentValue as string);
    this.handleConfigurationForm.emit(this.configurationForm);
    this.saveOrUpdateItem();

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.readEquipmentConfigurationData();
    this.manageLoadingStateOfData();
    this.getSilentSubmitState();
  }

  openFormInCreateMode(createMode: boolean): void {
    if (createMode) {
      setTimeout(() => {
        this.httpCustomErrorCode = null;
        this.configurationForm.reset();

        this.configurationForm
          .get('version')
          .setValue(sessionStorage.getItem(ConfigurationConstants.configurationVersion));
      }, 0);
    }
  }

  requestEquipmentConfigurationData(url: string): void {
    if (url && !this.createMode) {
      this.equipmentConfigActions.requestEquipmentConfiguration({
        urlPath: url,
      });
    }
  }

  manageLoadingStateOfData(): void {
    this.isLoading = combineLatest([
      this.store.select(selectedEquipmentConfiguration),
      this.store.select(selectAddedEquipmentHierarchy),
      this.store.select(selectAddressBookPage),
    ]).pipe(
      delay(0),
      map(
        ([getState, addState, addressState]: [
          IStoreApiItem<IEquipmentConfiguration>,
          IStoreApiItem<IEquipmentHierarchy>,
          IStoreApiItem<IAddressBookPage>,
        ]) => {
          return getState.isLoading || addState.isLoading || addressState.isLoading;
        },
      ),
    );

    this.cdr.detectChanges();
  }

  readEquipmentConfigurationData(): void {
    this.store
      .pipe(
        select(selectedEquipmentConfiguration),
        filter((response: IStoreApiItem<IEquipmentConfiguration>) => response.data !== null && !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IEquipmentConfiguration>) => {
        this.selectedConfigurationItem = response.data;
        sessionStorage.setItem(
          ConfigurationConstants.configurationVersion,
          this.selectedConfigurationItem.version.toString(),
        );
        this.configurationForm.patchValue(this.selectedConfigurationItem);
        this.configurationForm
          .get('version')
          .setValue(sessionStorage.getItem(ConfigurationConstants.configurationVersion));
        this.isLoading = of(false);

        this.cdr.detectChanges();
      });
  }

  updateItemAfterUserDeleteOrAddImages(): void {
    this.isSavedButtonPressed = true;
    this.saveOrUpdateItem();
    this.isLoading = of(false);
    this.isSavedButtonPressed = false;
  }

  getSilentSubmitState(): void {
    this.storage.watch(ConfigurationConstants.silentSaving).subscribe((response: boolean) => {
      if (response) {
        this.isSavedButtonPressed = response;
        this.saveOrUpdateItem();
        this.isLoading = of(false);
        this.isSavedButtonPressed = false;
      }
    });
  }

  saveOrUpdateItem(): void {
    if (!this.isSavedButtonPressed) return;

    this.saveConfigurationToSessionStorage();

    if (this.createMode) {
      this.handleCreateAction(this.equipmentConfigType);
      this.readAddEquipmentConfigurationState();

      return;
    }

    if (this.configurationForm.get('childrenIds').dirty) {
      this.saveChildrenIds();

      return;
    }

    this.handleEditAction(this.equipmentConfigType);
    this.readEditEquipmentConfigurationState();
  }

  saveChildrenIds(): void {
    const updateRequest: IUpdateTaskChildren = {
      version: +this.configurationForm.get('version').value,
      childrenIds: this.configurationForm.get('childrenIds').value,
      urlPath: `${this.itemUrl}/service-definition/${sessionStorage.getItem(ConfigurationConstants.taskId)}`,
    };

    this.tasksActions.requestUpdateTaskChildren(updateRequest);
    this.readUpdateTaskChildrenState();
  }

  saveConfigurationToSessionStorage(): void {
    const { aggregateId, typeId }: { aggregateId?: string; typeId?: string } = this.selectedTreeNode?.data || {};

    sessionStorage.setItem(ConfigurationConstants.categoryId, aggregateId);
    sessionStorage.setItem(ConfigurationConstants.typeId, typeId);
  }

  handleCreateAction(configType: string): void {
    this.setAddedType();

    switch (configType) {
      case this.configType.CATEGORY:
        this.addEquipmentHierarchyForType();
        break;
      case this.configType.TYPE:
        this.addEquipmentHierarchyForModel();
        break;
      default:
        this.addEquipmentHierarchyForCategory();
        break;
    }
  }

  handleEditAction(configType: string): void {
    this.setAddedType();

    switch (configType) {
      case this.configType.CATEGORY:
        this.editEquipmentHierarchy('categories', {
          ...this.configurationForm.value,
          id: this.configurationForm.value.aggregateId,
        });
        break;
      case this.configType.TYPE:
        this.editEquipmentHierarchy(`categories/${this.selectedTreeNode.data.aggregateId}/types`, {
          ...this.configurationForm.value,
          name: this.configurationForm.get('name').value,
          testType: this.configurationForm.get('protectorType').value,
          maxPressure: this.configurationForm.get('maxPressure')?.value ?? null,
          id: this.configurationForm.get(ConfigurationConstants.typeId).value,
        });
        break;
      default:
        this.editEquipmentHierarchy(
          `categories/${this.selectedTreeNode.data.aggregateId}/types/${this.selectedTreeNode.data.typeId}/models`,
          {
            ...this.configurationForm.value,
            maxPressure: this.configurationForm.get('maxPressure')?.value ?? null,
            id: this.configurationForm.get(ConfigurationConstants.modelId).value,
          },
        );
        break;
    }
  }

  addEquipmentHierarchyForCategory(): void {
    this.requestAddEquipmentHierarchy('categories', this.configurationForm.getRawValue());
  }

  addEquipmentHierarchyForType(): void {
    this.requestAddEquipmentHierarchy(`categories/${this.selectedTreeNode.data.aggregateId}/types`, {
      ...this.configurationForm.getRawValue(),
      testType: this.configurationForm.get('protectorType').value,
      id: this.configurationForm.get(ConfigurationConstants.typeId).value,
    });
  }

  addEquipmentHierarchyForModel(): void {
    this.requestAddEquipmentHierarchy(
      `categories/${this.selectedTreeNode.data.aggregateId}/types/${this.selectedTreeNode.data.typeId}/models`,
      {
        ...this.configurationForm.getRawValue(),
        id: this.configurationForm.get(ConfigurationConstants.modelId).value,
      },
    );
  }

  requestAddEquipmentHierarchy(urlPath: string, data: Partial<IEquipmentConfiguration>): void {
    this.equipmentHierarchyActions.requestAddEquipmentHierarchy({ data, urlPath });
  }

  editEquipmentHierarchy(urlPath: string, data: any): void {
    this.equipmentHierarchyActions.requestEditEquipmentHierarchy({ data, urlPath });
  }

  setAddedType(): void {
    switch (this.equipmentConfigType) {
      case this.configType.MODEL:
        this.addedType = this.translateService.instant('CONFIGURATION.STR_MODEL') as string;
        break;
      case this.configType.TYPE:
        this.addedType = this.translateService.instant('CONFIGURATION.STR_TYPE') as string;
        break;
      default:
        this.addedType = this.translateService.instant('CONFIGURATION.STR_CATEGORY') as string;
        break;
    }

    this.setAddedTypeForCreation();
  }

  setAddedTypeForCreation(): void {
    if (!this.createMode) return;

    if (this.equipmentConfigType === this.configType.CATEGORY) {
      this.addedType = this.translateService.instant('CONFIGURATION.STR_TYPE') as string;
    }

    if (this.equipmentConfigType === this.configType.TYPE) {
      this.addedType = this.translateService.instant('CONFIGURATION.STR_MODEL') as string;
    }
  }

  readAddEquipmentConfigurationState(): void {
    this.store
      .pipe(
        select(selectAddedEquipmentHierarchy),
        filter((state: IStoreApiItem<IEquipmentHierarchy>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IEquipmentHierarchy>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.ADD_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            { type: this.addedType },
          );

          this.equipmentHierarchyActions.requestEquipmentHierarchy();

          this.resetCreateModeStateAfterSave.emit(false);
        } else {
          this.httpCustomErrorCode = response.errors.error?.code.toString();
          let type: string = null;

          if (
            this.httpCustomErrorCode === EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE.value
          ) {
            setTimeout(() => {
              this.configurationForm.get('name').setErrors({ invalid: true });
            }, 0);

            type = CommonConstants.notificationType.HIDDEN;
          }

          this.callErrorNotification(
            this.httpCustomErrorCode,
            {
              ...ConfigurationNotificationConstants.notificationCodes,
              ...EquipmentNotificationConstants.notificationCodes,
            },
            type,
          );
        }

        this.configurationForm.markAsPristine();

        this.resetSubmitButtonState.emit(false);
        this.cdr.detectChanges();
      });
  }

  readEditEquipmentConfigurationState(): void {
    this.isLoading = of(false);

    this.store
      .pipe(
        select(selectEditedEquipmentHierarchy),
        filter((state: IStoreApiItem<IEditEquipmentHierarchy>) => !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IEditEquipmentHierarchy>) => {
        if (response.isSuccess) {
          this.equipmentHierarchyActions.requestEquipmentHierarchy();

          this.requestEquipmentConfigurationData(this.itemUrl);

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.EDIT_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            { type: this.addedType },
          );

          this.configurationForm.markAsPristine();

          this.storage.set(ConfigurationConstants.silentSaving, false).subscribe(() => {
            return;
          });
        } else {
          this.httpCustomErrorCode = response.errors.error?.code?.toString();

          this.markFormAsPristineIfMigrationIsInProgress(this.httpCustomErrorCode as string);

          if (
            this.httpCustomErrorCode === EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE.value
          ) {
            setTimeout(() => {
              this.configurationForm.get('name').setErrors({ invalid: true });
            }, 0);

            this.callErrorNotification(
              EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE,
              {
                ...ConfigurationNotificationConstants.notificationCodes,
                ...EquipmentNotificationConstants.notificationCodes,
              },
              CommonConstants.notificationType.HIDDEN,
            );
          } else {
            if (
              this.httpCustomErrorCode ===
              EquipmentNotificationConstants.notificationCodes
                .EQUIPMENT_HIERARCHY_TEST_TYPE_CANNOT_BE_CHANGE_TO_BA_CYLINDER.value
            ) {
              setTimeout(() => {
                this.configurationForm.get('protectorType').setErrors({ invalid: true });
              }, 0);
            }

            this.callErrorNotification(
              this.httpCustomErrorCode,
              {
                ...ConfigurationNotificationConstants.notificationCodes,
                ...EquipmentNotificationConstants.notificationCodes,
              },
              null,
            );
          }

          this.storage.set(ConfigurationConstants.silentSaving, false).subscribe(() => {
            return;
          });
        }

        this.resetSubmitButtonState.emit(false);
        this.cdr.detectChanges();
      });
  }

  readUpdateTaskChildrenState(): void {
    this.store
      .pipe(
        select(selectUpdatedTaskChildren),
        filter((state: IStoreApiItem<IUpdateTaskChildren>) => !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IUpdateTaskChildren>) => {
        if (response.isSuccess) {
          this.setAddedType();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.EDIT_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            { type: this.addedType },
          );

          sessionStorage.removeItem(ConfigurationConstants.taskId);

          const newVersion: number = (structuredClone(response.data).version += 1);

          this.configurationForm.get('version').patchValue(newVersion);

          this.requestEquipmentConfigurationData(this.itemUrl);
        } else {
          this.httpCustomErrorCode = response.errors?.error?.code.toString();

          if (
            this.httpCustomErrorCode === ConfigurationNotificationConstants.notificationCodes.CIRCULAR_INCLUSION.value
          ) {
            this.callErrorNotification(
              ConfigurationNotificationConstants.notificationCodes.CIRCULAR_INCLUSION,
              ConfigurationNotificationConstants.notificationCodes,
            );
          } else {
            this.callErrorNotification(
              ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST,
              ConfigurationNotificationConstants.notificationCodes,
            );
          }
        }

        this.configurationForm.get('childrenIds').markAsPristine();
        this.resetSubmitButtonState.emit(false);
        this.cdr.detectChanges();
      });
  }

  markFormAsPristineIfMigrationIsInProgress(errorCode: string): void {
    if (
      errorCode ===
      ConfigurationNotificationConstants.notificationCodes.OPERATION_BLOCKED_DURING_THE_MIGRATION_IN_PROGRESS.value
    ) {
      this.configurationForm.markAsPristine();
      this.configurationForm.markAsUntouched();
    }
  }

  callErrorNotification(
    errorCode: string | INotificationMessage,
    codes: INotificationConstant,
    notificationType?: string,
  ): void {
    this.notificationsService.requestShowNotification(
      notificationType || CommonConstants.notificationType.ERROR,
      errorCode,
      codes,
    );
  }
}
