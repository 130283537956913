import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';
import { filter, map, takeUntil } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { getPressureUnit } from 'src/app/common/utils';
import { ConfigurationConstants, ConfigurationModuleRoutes } from 'src/app/configuration/constants';
import { AddressBookActions } from 'src/app/configuration/state/actions';

import {
  CommonConstants,
  DropdownService,
  IApplicationState,
  IEntryModel,
  IStoreApiItem,
  IStoreApiList,
  PressureUnitPipe,
} from 'src/app/common';
import {
  IAddressBook,
  IAddressBookPage,
  ICustomTreeNode,
  IEquipmentConfigType,
  IEquipmentConfiguration,
  IManufacturersList,
} from 'src/app/configuration/models';
import {
  selectAddressBookPage,
  selectProtectorTypeValues,
} from 'src/app/configuration/state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrl: './details-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsTabComponent extends OnDestroyMixin() implements OnChanges {
  @Input() configurationForm: FormGroup;
  @Input() selectedConfigurationItem: IEquipmentConfiguration;
  @Input() selectedTreeNode: ICustomTreeNode<undefined>;
  @Input() createMode: boolean;
  @Input() equipmentConfigType: string;
  @Input() httpCustomErrorCode: string;
  @Input() itemUrl: string;

  @Output() saveItemAfterUploadIsDone: EventEmitter<void> = new EventEmitter<void>();

  pressureDisplayUnit: string;
  convertedPressureValue: number;
  pressureValue: number;

  protectorTypeValues: IEntryModel[];
  manufacturersList: IManufacturersList[];
  manufacturerMap: Map<string, string> = new Map<string, string>();
  selectedManufacturer: IAddressBook;
  manufacturerQuery: { page: number; size: number; sort: string; types: string } = {
    page: 0,
    size: 1000,
    sort: 'organizationName,ASC',
    types: 'MANUFACTURER',
  };
  configType: IEquipmentConfigType = ConfigurationConstants.EquipmentConfigType;

  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  translateService: TranslateService = inject(TranslateService);
  addressBookActions: AddressBookActions = inject(AddressBookActions);
  pressureUnitPipe: PressureUnitPipe = inject(PressureUnitPipe);
  dropdownService: DropdownService = inject(DropdownService);
  router: Router = inject(Router);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    this.addOrRemovePressureInputForCylinderModel();

    const currentNodeLabel: string = changes.selectedTreeNode?.currentValue?.label as string;
    const previousNodeLabel: string = changes.selectedTreeNode?.previousValue?.label as string;

    if (currentNodeLabel === previousNodeLabel || (currentNodeLabel && !changes.selectedTreeNode?.previousValue)) {
      this.readPressureUnit();
      this.readTestTypeDropdownValues();
      this.readManufacturerData();
    }
  }

  addOrRemovePressureInputForCylinderModel(): void {
    if (
      (this.selectedConfigurationItem?.modelId && this.selectedConfigurationItem?.hasCylinderType) ||
      (this.createMode && this.selectedTreeNode?.data?.isCylinderType)
    ) {
      this.configurationForm.addControl('maxPressure', new FormControl(null, [Validators.required]));
    } else {
      this.configurationForm.removeControl('maxPressure');
      this.configurationForm.updateValueAndValidity();
    }
  }

  readPressureUnit(): void {
    getPressureUnit(this.store, this.destroy).subscribe((unit: string) => {
      this.processPressureUnitData(unit);

      this.cdr.detectChanges();
    });
  }

  processPressureUnitData(unit: string): void {
    this.pressureDisplayUnit = unit;
    this.pressureValue = this.selectedConfigurationItem?.maxPressure;

    if (this.pressureValue) {
      const convertedValue: number = this.pressureUnitPipe.transform(this.selectedConfigurationItem.maxPressure, [
        this.pressureDisplayUnit,
        false,
      ]) as number;

      this.configurationForm.get('maxPressure').setValue(convertedValue);
    }
  }

  readTestTypeDropdownValues(): void {
    this.store
      .pipe(
        select(selectProtectorTypeValues),
        filter((types: IStoreApiList<IEntryModel[]>) => !types.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((types: IStoreApiList<any[]>) => {
        const localizedData: IEntryModel[] = ConfigurationConstants.protectorTypes.types;
        const label: string = 'label';
        const baCylinderType: string = 'BA_CYLINDER';

        this.protectorTypeValues = sortBy(
          types.data?.map((pType: IEntryModel) => ({
            ...pType,
            label: this.translateService.instant(
              localizedData.find((t: IEntryModel) => t.value === pType.value)?.localizedName || pType.value,
            ),
          })),
          label,
        );

        this.configurationForm.get('protectorType').enable();

        this.disableProtectorTypeDropdown(baCylinderType);

        this.configurationForm.get('protectorType').setValue(this.selectedConfigurationItem?.protectorType);

        this.cdr.detectChanges();
      });
  }

  disableProtectorTypeDropdown(type: string): void {
    if (
      this.selectedConfigurationItem &&
      this.selectedConfigurationItem.protectorType === type &&
      this.selectedConfigurationItem.hasModels
    ) {
      this.configurationForm.get('protectorType').disable();
    }
  }

  readManufacturerData(): void {
    this.store
      .pipe(
        select(selectAddressBookPage),
        filter((manufacturers: IStoreApiItem<IAddressBookPage>) => !manufacturers.isLoading),
        map((state: IStoreApiList<IAddressBookPage>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((manufacturers: IAddressBookPage) => {
        if (!manufacturers) {
          return;
        }

        manufacturers.entries?.forEach((address: IAddressBook) => {
          this.manufacturerMap.set(address.aggregateId, address.organizationName);
        });

        if (manufacturers.totalPages > 0 && manufacturers.currentPage < manufacturers.totalPages - 1) {
          this.addressBookActions.requestAddressBook({
            ...this.manufacturerQuery,
            page: manufacturers.currentPage + 1,
          });
        } else {
          this.manufacturersList = Array.from(
            this.manufacturerMap,
            ([manufacturerAggregateId, organizationName]: [string, string]) => ({
              manufacturerAggregateId,
              organizationName,
            }),
          );

          this.selectedManufacturer = this.manufacturersList.filter((m: IManufacturersList) => {
            return m.manufacturerAggregateId === this.selectedConfigurationItem?.manufacturer?.manufacturerAggregateId;
          })[0] as IAddressBook;

          this.addMissingAddressBook();
        }

        this.cdr.detectChanges();
      });
  }

  addMissingAddressBook(): void {
    if (this.selectedManufacturer) {
      if (!this.manufacturerMap.has(this.selectedManufacturer.manufacturerAggregateId)) {
        this.manufacturersList.unshift({
          organizationName: this.selectedManufacturer.organizationName,
          manufacturerAggregateId: this.selectedManufacturer.manufacturerAggregateId,
        });
      }

      this.configurationForm.get('manufacturerAggregateId').setValue(this.selectedManufacturer.manufacturerAggregateId);
    }
  }

  convertPressureUnit(event: any): void {
    switch (this.pressureDisplayUnit) {
      case CommonConstants.pressureDisplayUnit.psi:
        this.convertedPressureValue = event.target.value * CommonConstants.PSI_CONVERSION_VALUE;
        break;
      case CommonConstants.pressureDisplayUnit.mpa:
        this.convertedPressureValue = event.target.value * CommonConstants.MPA_CONVERSION_VALUE;
        break;
      default:
        this.convertedPressureValue = event.target.value;
        break;
    }
  }

  getUploadedImages(filesName: string[]): void {
    this.configurationForm.get('mediaLinks').setValue(filesName);
    this.configurationForm.updateValueAndValidity();

    this.configurationForm.markAsDirty();
    this.saveItemAfterUploadIsDone.emit();

    this.cdr.detectChanges();
  }

  navigateToAddAddress(): void {
    const objToStored: any = {
      ...this.configurationForm.value,
      touched: this.configurationForm.touched,
    };

    sessionStorage.setItem(ConfigurationConstants.comeFromEquipmentHierarchy, JSON.stringify(objToStored));

    this.configurationForm.markAsPristine();
    this.router.navigate([ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.addressBook, 'create']);
  }
}
