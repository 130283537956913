<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="add-equipment-hierarchy-item"
    id="add-equipment-hierarchy-btn"
    data-test-id="configuration.add_equipment_hierarchy_btn"
    (click)="onCreateClick($event)"
    [disabled]="disableAddBtn || isModalOpened || form?.dirty"
    [pTooltip]="addButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addButtonTooltipContent>
      <div data-test-id="configuration.add_equipment_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_ADD_NEW_EQUIPMENT_CONFIGURATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="save-equipment-hierarchy"
    [disabled]="isModalOpened || !form?.dirty || !form?.valid"
    (click)="onSubmit($event)"
    id="submit-equipment-hierarchy-btn"
    data-test-id="configuration.submit_equipment_hierarchy_btn"
    [pTooltip]="'CONFIGURATION.STR_SAVE_TOOLTIP' | translate"
    tooltipPosition="top"
    positionTop="-10"
    positionLeft="5"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon size="inline" name="save"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="copy-equipment-hierarchy-item"
    id="copy-equipment-hierarchy-btn"
    [disabled]="!selectedItem || isModalOpened || form?.dirty"
    (click)="onCopyClick($event)"
    data-test-id="configuration.copy_equipment_hierarchy_btn"
    [pTooltip]="'CONFIGURATION.STR_DUPLICATE_TOOLTIP' | translate"
    tooltipPosition="top"
    positionTop="-10"
    positionLeft="5"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon name="copy-content" iconSet="core"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="delete-equipment-hierarchy-item"
    id="delete-equipment-hierarchy-btn"
    [disabled]="!selectedItem || isModalOpened"
    (click)="onDeleteClick($event)"
    data-test-id="configuration.delete_equipment_hierarchy_btn"
    [pTooltip]="deleteButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #deleteButtonTooltipContent>
      <div data-test-id="configuration.delete_equipment_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_DELETE_EQUIPMENT_CONFIGURATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon name="delete"></odx-icon>
  </button>
</div>
