<ng-template odxModal #addServiceModal let-modal="close">
  <odx-modal-header>
    <odx-area-header>
      @if (router.url?.includes(ConfigurationModuleRoutes.createTask)) {
        {{ 'CONFIGURATION.STR_NEW_TASK' | translate }}
      } @else {
        {{ 'CONFIGURATION.STR_EDIT_TASK' | translate }}
      }
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    @if (isLoading) {
      <div [odxLoadingSpinner]="isLoading === true" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
    } @else {
      <form odxForm [formGroup]="taskForm" (ngSubmit)="onSubmit()">
        <section odxLayout="grid">
          <div odxLayout="12 4@phone 4@tablet 4@desktop">
            <odx-form-field variant="horizontal" label="{{ 'CONFIGURATION.STR_SET_RECURRENCE' | translate }}">
              <input
                type="number"
                odxFormFieldControl
                ignisDisableAutocomplete
                id="interval"
                data-test-id="configuration.interval"
                formControlName="duration"
                oninput="this.value = this.valueAsNumber"
                min="1"
              />
            </odx-form-field>
          </div>
          <div odxLayout="12 8@phone 4@tablet 4@desktop">
            <div
              class="odx-form-field interval-pattern-padding-top"
              [ngClass]="{
                'has-error': !taskForm.get('pattern').value && taskForm.get('duration').value,
              }"
            >
              <div class="w-100">
                <p-dropdown
                  #ddTaskName
                  optionValue="value"
                  ngDefaultControl
                  [dropdownIcon]="dropdownIconCSSClass"
                  [optionLabel]="localizedName | translate"
                  [options]="intervalList"
                  data-test-id="configuration.interval_LIST"
                  formControlName="pattern"
                  (onShow)="dropdownService.openDropdown(ddTaskName)"
                  (onHide)="dropdownService.closeDropdown(ddTaskName)"
                >
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <span>{{ selectedItem?.localizedName | translate }}</span>
                  </ng-template>
                  <ng-template let-option pTemplate="item">
                    <span>{{ option?.localizedName | translate }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div odxLayout="12 12@desktop">
            <odx-form-field
              variant="full"
              label="{{ 'CONFIGURATION.STR_TASK_NAME' | translate }}"
              [ngClass]="{ 'has-error': httpCustomErrorCode && taskForm.get('taskName').invalid }"
            >
              <input
                type="text"
                odxFormFieldControl
                ignisDisableAutocomplete
                id="taskName"
                data-test-id="configuration.taskName"
                formControlName="taskName"
                placeholder="{{ 'CONFIGURATION.STR_TASK_NAME_PLACEHOLDER' | translate }}"
                (input)="markedAsManualUpdated()"
              />

              <odx-icon
                *ngIf="httpCustomErrorCode && taskForm.get('taskName').invalid"
                name="info"
                class="custom-validation-icon"
                iconSet="core"
                [odxTooltip]="taskNameTooltip"
                [odxTooltipVisible]="httpCustomErrorCode && taskForm.get('taskName').invalid"
              >
                <ng-template #taskNameTooltip>
                  <div data-test-id="configuration.taskName_ERROR_TOOLTIP">
                    {{ 'CONFIGURATION_NOTIFICATION.STR_TASK_NAME_SHOULD_BE_UNIQUE' | translate }}
                  </div>
                </ng-template>
              </odx-icon>
            </odx-form-field>
          </div>
          <div odxLayout="12 12@desktop">
            <odx-form-field variant="full" label="{{ 'CONFIGURATION.STR_DESCRIPTION' | translate }}">
              <textarea
                ignisDisableAutocomplete
                odxFormFieldControl
                class="custom-form-textarea"
                id="description"
                data-test-id="configuration.description"
                formControlName="description"
                rows="3"
              ></textarea>
            </odx-form-field>
          </div>
        </section>
      </form>
    }
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'CONFIGURATION.STR_CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      (click)="onSubmit()"
      [disabled]="!taskForm.dirty || taskForm.invalid || (isSubmitting | async)"
      type="button"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'CONFIGURATION.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="$event ? navigateBackWhenOpen() : navigateBackWhenClosed()"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_TAKS' | translate }}
  </div>
</ignis-confirmation-modal>

<ignis-confirm-change-category-type-model
  [isOpen]="openConfirmUpdateHierarchyBanner"
  [addedType]="taskType"
  (handleConfirmationModificationModal)="closeConfirmBanner($event)"
>
</ignis-confirm-change-category-type-model>
