import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ICustomTreeNode, IEquipmentHierarchy } from 'src/app/configuration/models';

@Component({
  selector: 'ignis-eq-hierarchy-filter',
  templateUrl: './eq-hierarchy-filter.component.html',
  styleUrl: './eq-hierarchy-filter.component.scss',
})
export class EqHierarchyFilterComponent implements OnInit {
  @Input() hierarchyData: IEquipmentHierarchy[] | ICustomTreeNode<undefined>[];
  @Output() emitFilteredHierarchyData: EventEmitter<IEquipmentHierarchy[] | ICustomTreeNode<undefined>[]> =
    new EventEmitter<IEquipmentHierarchy[] | ICustomTreeNode<undefined>[]>();

  searchControl: FormControl = new FormControl('');
  searchValue: string = '';

  ngOnInit(): void {
    const copyOfHierarchyData: IEquipmentHierarchy[] | ICustomTreeNode<undefined>[] = this.hierarchyData;

    this.searchControl.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe((value: string) => {
      this.searchValue = value;

      if (this.searchValue.length > 0) {
        const result: IEquipmentHierarchy[] = this.filterTreeByLabel(
          copyOfHierarchyData,
          this.searchValue,
        ) as IEquipmentHierarchy[];

        this.emitFilteredHierarchyData.emit(result);
      } else {
        this.emitFilteredHierarchyData.emit(copyOfHierarchyData);
      }
    });
  }

  filterTreeByLabel(nodes: TreeNode[], searchTerm: string): TreeNode[] {
    const filteredNodes: TreeNode[] = [];

    for (const node of nodes) {
      const matchingChildren: TreeNode[] = this.getMatchingChildren(node, searchTerm);
      const isMatching: boolean = this.isNodeMatching(node, searchTerm);

      if (isMatching || matchingChildren.length > 0) {
        filteredNodes.push(this.createFilteredNode(node, isMatching, matchingChildren));
      }
    }

    return filteredNodes;
  }

  getMatchingChildren(node: TreeNode, searchTerm: string): TreeNode[] {
    return node.children ? this.filterTreeByLabel(node.children, searchTerm) : [];
  }

  isNodeMatching(node: TreeNode, searchTerm: string): boolean {
    return node.label?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
  }

  createFilteredNode(
    node: ICustomTreeNode<undefined>,
    isMatching: boolean,
    matchingChildren: TreeNode[],
  ): ICustomTreeNode<undefined> {
    return {
      ...node,
      borderHighlighted: isMatching || matchingChildren.length > 0,
      expanded: matchingChildren.length > 0,
      children: matchingChildren.length > 0 ? matchingChildren : undefined,
    };
  }
}
