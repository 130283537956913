import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from '../../../../common';

@Component({
  selector: 'ignis-equipment-hierarchy-view-footer',
  templateUrl: './equipment-hierarchy-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EquipmentHierarchyViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() disableAddBtn: boolean;
  @Input() isModalOpened: boolean;

  @Output() handleCopyNode: EventEmitter<any> = new EventEmitter<any>();

  private readonly checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onCopyClick(event: MouseEvent): void {
    event.stopPropagation();
    this.handleCopyNode.emit();
  }
}
