import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CommonModule, CustomDigitDecimalNumberDirective } from 'src/app/common';
import { AddressBookListViewFooterComponent } from '../components/address-book/address-book-list-view-footer/address-book-list-view-footer.component';
import { AddressBookListComponent } from '../components/address-book/address-book-list.component';
import { CreateUpdateAddressBookViewFooterComponent } from '../components/address-book/create-update-address-book/create-update-address-book-view-footer/create-update-address-book-view-footer.component';
import { CreateUpdateAddressBookComponent } from '../components/address-book/create-update-address-book/create-update-address-book.component';
import { ConfirmChangeCategoryTypeModelComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/confirm-change-category-type-model/confirm-change-category-type-model.component';
import { ChecklistFormComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/create-update-checklist/checklist-form/checklist-form.component';
import { CreateUpdateChecklistComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/create-update-checklist/create-update-checklist.component';
import { CreateUpdateEqHierarchyItemComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/create-update-eq-hierarchy-item.component';
import { CreateUpdateTaskComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/create-update-task/create-update-task.component';
import { DetailsTabComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/details-tab/details-tab.component';
import { EqHierarchyUploadImageControlComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/details-tab/eq-hierarchy-upload-image-control/eq-hierarchy-upload-image-control.component';
import { TasksSectionComponent } from '../components/equipment-hierarchy/create-update-eq-hierarchy-item/details-tab/tasks-section/tasks-section.component';
import { EqHierarchyFilterComponent } from '../components/equipment-hierarchy/eq-hierarchy-filter/eq-hierarchy-filter.component';
import { EqHierarchyThumbnailComponent } from '../components/equipment-hierarchy/eq-hierarchy-thumbnail/eq-hierarchy-thumbnail.component';
import { EquipmentHierarchyViewFooterComponent } from '../components/equipment-hierarchy/equipment-hierarchy-view-footer/equipment-hierarchy-view-footer.component';
import { EquipmentHierarchyComponent } from '../components/equipment-hierarchy/equipment-hierarchy.component';
import { ImportEquipmentHierarchyModalComponent } from '../components/equipment-hierarchy/import-equipment-hierarchy-modal/import-equipment-hierarchy-modal.component';
import { CreateUpdateLocationHierarchyComponent } from '../components/location-hierarchy/create-update-location-hierarchy/create-update-location-hierarchy.component';
import { LocationHierarchyViewFooterComponent } from '../components/location-hierarchy/location-hierarchy-view-footer/location-hierarchy-view-footer.component';
import { LocationHierarchyComponent } from '../components/location-hierarchy/location-hierarchy.component';
import { FiregroundSettingsSectionComponent } from '../components/organization-settings/fireground-settings-section/fireground-settings-section.component';
import { OrganizationSettingsFooterViewComponent } from '../components/organization-settings/organization-settings-footer-view/organization-settings-footer-view.component';
import { OrganizationSettingsSectionComponent } from '../components/organization-settings/organization-settings-section/organization-settings-section.component';
import { OrganizationSettingsComponent } from '../components/organization-settings/organization-settings.component';
import { RamSettingsSectionComponent } from '../components/organization-settings/ram-settings-section/ram-settings-section.component';
import { EquipmentConfigurationService } from '../services/equipment-configuration.service';
import { LocationConfigurationService } from '../services/location-configuration.service';
import { OrganizationSettingsService } from '../services/organization-settings.service';
import {
  AddressBookEffects,
  LocationConfigEffects,
  LocationHierarchyEffects,
  ProtectorTypeEffects,
  TasksEffects,
} from '../state/effects';
import { ChecklistEffects } from '../state/effects/checklist/checklist.effects';
import { EquipmentConfigEffects } from '../state/effects/equipment-config';
import { EquipmentHierarchyEffects } from '../state/effects/equipment-hierarchy';
import { LocationTypeEffects } from '../state/effects/location-type';
import { OrganizationSettingsEffects } from '../state/effects/organization-settings';
import { ConfigurationOverviewComponent } from './../components/configuration-overview/configuration-overview.component';
import { configurationReducer } from './../state/reducers/configuration.reducers';
import { ConfigurationRoutingModule } from './configuration-routing.module';

@NgModule({
  declarations: [
    ChecklistFormComponent,
    AddressBookListComponent,
    LocationHierarchyComponent,
    EquipmentHierarchyComponent,
    RamSettingsSectionComponent,
    CreateUpdateTaskComponent,
    OrganizationSettingsComponent,
    ConfigurationOverviewComponent,
    CreateUpdateChecklistComponent,
    CreateUpdateAddressBookComponent,
    AddressBookListViewFooterComponent,
    FiregroundSettingsSectionComponent,
    LocationHierarchyViewFooterComponent,
    OrganizationSettingsSectionComponent,
    EquipmentHierarchyViewFooterComponent,
    CreateUpdateLocationHierarchyComponent,
    ConfirmChangeCategoryTypeModelComponent,
    OrganizationSettingsFooterViewComponent,
    CreateUpdateAddressBookViewFooterComponent,
    ImportEquipmentHierarchyModalComponent,
    EqHierarchyFilterComponent,
    EqHierarchyThumbnailComponent,
    CreateUpdateEqHierarchyItemComponent,
    DetailsTabComponent,
    EqHierarchyUploadImageControlComponent,
    TasksSectionComponent,
  ],
  imports: [
    ConfigurationRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    AccordionModule,
    TreeModule,
    StoreModule.forFeature('configuration', configurationReducer),
    EffectsModule.forFeature([
      AddressBookEffects,
      ChecklistEffects,
      EquipmentConfigEffects,
      LocationConfigEffects,
      EquipmentHierarchyEffects,
      LocationHierarchyEffects,
      LocationTypeEffects,
      OrganizationSettingsEffects,
      ProtectorTypeEffects,
      TasksEffects,
    ]),
    SimplebarAngularModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    ClickOutsideModule,
    CustomDigitDecimalNumberDirective,
  ],
  providers: [EquipmentConfigurationService, LocationConfigurationService, OrganizationSettingsService],
})
export class ConfigurationModule {}
