@if ((isLoading | async) === true && !createMode) {
  <div [odxLoadingSpinner]="(isLoading | async) === true" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
}

@if (createMode) {
  @if (!equipmentConfigType) {
    <h2 class="mb-5 side-panel-title">{{ 'CONFIGURATION.STR_ADD_CATEGORY' | translate }}</h2>
  } @else if (equipmentConfigType === configType.CATEGORY) {
    <h2 class="mb-5 side-panel-title">{{ 'CONFIGURATION.STR_ADD_TYPE' | translate }}</h2>
  } @else if (equipmentConfigType === configType.TYPE) {
    <h2 class="mb-5 side-panel-title">{{ 'CONFIGURATION.STR_ADD_MODEL' | translate }}</h2>
  }
} @else {
  @if (selectedConfigurationItem && (isLoading | async) === false) {
    @if (selectedConfigurationItem && selectedConfigurationItem?.modelId) {
      <h2 class="mb-5 side-panel-title">
        {{ 'CONFIGURATION.STR_MODEL' | translate }}
      </h2>
    } @else if (selectedConfigurationItem && selectedConfigurationItem?.typeId) {
      <h2 class="mb-5 side-panel-title">
        {{ 'CONFIGURATION.STR_TYPE' | translate }}
      </h2>
    } @else {
      <h2 class="mb-5 side-panel-title">{{ 'CONFIGURATION.STR_CATEGORY' | translate }}</h2>
    }
  }
}

@if ((selectedConfigurationItem && (isLoading | async) === false) || createMode) {
  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div
          id="details-tab-wrap"
          data-test-id="configuration.details_tab_wrap"
          [ngClass]="{
            'tab-is-active': true,
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            (click)="navigateToDetailsTab()"
            id="detailsTab"
            data-test-id="configuration.location_tab"
          >
            {{ 'CONFIGURATION.STR_DETAILS_TAB' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <ignis-details-tab
    [configurationForm]="configurationForm"
    [itemUrl]="itemUrl"
    [selectedConfigurationItem]="selectedConfigurationItem"
    [equipmentConfigType]="equipmentConfigType"
    [createMode]="createMode"
    [selectedTreeNode]="selectedTreeNode"
    [httpCustomErrorCode]="httpCustomErrorCode"
    (saveItemAfterUploadIsDone)="updateItemAfterUserDeleteOrAddImages()"
  ></ignis-details-tab>
}
