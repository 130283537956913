<form odxForm [formGroup]="configurationForm">
  <section odxLayout="grid">
    <div odxLayout="12">
      <odx-form-field
        variant="horizontal"
        label="{{ 'CONFIGURATION.STR_NAME' | translate }}"
        [readonly]="configurationForm.get('name').disabled"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="name"
          data-test-id="configuration.name"
          formControlName="name"
        />

        <odx-icon
          *ngIf="httpCustomErrorCode && configurationForm.get('name').invalid"
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="eqHierarchyNameTooltipContent"
          [odxTooltipVisible]="httpCustomErrorCode && configurationForm.get('name').invalid"
        >
          <ng-template #eqHierarchyNameTooltipContent>
            <div id="name-error-tooltip" data-test-id="configuration.name_error_tooltip">
              {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_ERROR_CODES_NAME_SHOULD_BE_UNIQUE' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>
    </div>

    @if (
      (selectedConfigurationItem?.modelId && selectedConfigurationItem?.hasCylinderType) ||
      (createMode && equipmentConfigType === configType.TYPE && selectedTreeNode?.data?.isCylinderType)
    ) {
      <div odxLayout="12">
        <odx-form-field
          variant="horizontal"
          label=" {{ 'CONFIGURATION.STR_MAX_PRESSURE' | translate }} ({{ pressureDisplayUnit }})"
          [readonly]="configurationForm.get('maxPressure')?.disabled"
        >
          <input
            type="number"
            ignisDisableAutocomplete
            odxFormFieldControl
            oninput="this.value = this.valueAsNumber"
            [min]="1"
            id="maxPressure"
            data-test-id="configuration.max_pressure"
            formControlName="maxPressure"
            (keyup)="convertPressureUnit($event)"
          />
        </odx-form-field>
      </div>
    }

    @if (
      (selectedConfigurationItem?.typeId && !selectedConfigurationItem?.modelId && !createMode) ||
      (createMode && equipmentConfigType === configType.CATEGORY)
    ) {
      <div odxLayout="12 12@mobile">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION.STR_TEST_TYPE' | translate }}"
          [readonly]="configurationForm.get('protectorType').disabled"
        >
          <div
            class="w-100"
            id="protectorType-wrap"
            data-test-id="configuration.test_type_wrap"
            [pTooltip]="configurationForm.get('protectorType').disabled ? cylinderDropdownTooltipContent : null"
            tooltipPosition="top"
            positionTop="-10"
          >
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              class="custom-dropdown-form"
              odxFormFieldControl
              ngDefaultControl
              id="protectorType"
              data-test-id="configuration.test_type"
              formControlName="protectorType"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="protectorTypeValues"
              [appendTo]="'body'"
              [placeholder]="'CONFIGURATION.STR_TEST_TYPE_PLACEHOLDER' | translate"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.label }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
            <ng-template #cylinderDropdownTooltipContent>
              <div id="test-type-tooltip" data-test-id="configuration.test_type_tooltip">
                {{ 'CONFIGURATION.STR_TEST_TYPE_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </div>
        </odx-form-field>
      </div>
    }

    @if (selectedConfigurationItem?.modelId || (createMode && equipmentConfigType === configType.TYPE)) {
      <div odxLayout="12 12@mobile" class="position-relative d-flex">
        <odx-form-field
          variant="horizontal"
          label=" {{ 'CONFIGURATION.STR_EQUIPMENT_MANUFACTURER' | translate }}"
          [readonly]="configurationForm.get('manufacturerAggregateId').disabled"
          class="custom-width"
        >
          <div class="w-100">
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              ngDefaultControl
              class="custom-dropdown-form"
              odxFormFieldControl
              optionValue="manufacturerAggregateId"
              optionLabel="organizationName"
              appendTo="body"
              formControlName="manufacturerAggregateId"
              data-test-id="configuration.equipment_manufacturer"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="manufacturersList"
              [appendTo]="'body'"
              [placeholder]="'CONFIGURATION.STR_EQUIPMENT_MANUFACTURER_PLACEHOLDER' | translate"
              [emptyMessage]="'CONFIGURATION.STR_NO_RESULTS_FOUND' | translate"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.organizationName }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </odx-form-field>
        <a
          odxButton
          variant="secondary"
          class="add-address-btn mb-3"
          id="navigate-to-address"
          data-test-id="configuration.navigate_to_address_book"
          (click)="navigateToAddAddress()"
        >
          <odx-icon name="add"></odx-icon>
        </a>
      </div>
    }

    <div odxLayout="12 12@mobile">
      <div class="odx-form-field--horizontal odx-form-field">
        <label class="odx-form-field__label" for="image">
          <odx-form-field-label class="odx-form-field-label">
            {{ 'CONFIGURATION.STR_IMAGE' | translate }}
          </odx-form-field-label>
        </label>

        <div class="w-100">
          <ignis-eq-hierarchy-upload-image-control
            (emitUploadedFiles)="getUploadedImages($event)"
            [createMode]="createMode"
            [configurationForm]="configurationForm"
          ></ignis-eq-hierarchy-upload-image-control>
        </div>
      </div>
    </div>
    <div odxLayout="12 12@mobile">
      <ignis-tasks-section
        [configurationForm]="configurationForm"
        [selectedConfigurationItem]="selectedConfigurationItem"
        [selectedTreeNode]="selectedTreeNode"
        [createMode]="createMode"
        [equipmentConfigType]="equipmentConfigType"
        [httpCustomErrorCode]="httpCustomErrorCode"
        [itemUrl]="itemUrl"
      >
      </ignis-tasks-section>
    </div>
  </section>
</form>
