import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { ApplicationState, CommonConstants, DropdownService, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PropertyBag } from 'src/app/common/models/common.model';
import { INotificationConstant } from 'src/app/common/models/notifications.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { prepareNumericValueData } from 'src/app/common/utils/general-helper/general-helpers';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { ConfigurationModuleRoutes } from 'src/app/configuration/constants/configuration-module-routes.constants';
import { IEquipmentConfiguration } from 'src/app/configuration/models';
import { ChecklistActions, EquipmentConfigActions } from 'src/app/configuration/state/actions';
import { IChecklist } from './../../../../models/checklist.model';
import {
  selectedEditedChecklist,
  selectedEquipmentConfiguration,
} from './../../../../state/selectors/configuration.selectors';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ChecklistParameterTypes,
  ConfigurationConstants,
} from 'src/app/configuration/constants/configuration.constants';
import {
  selectedDeleteChecklist,
  selectedNewChecklist,
} from 'src/app/configuration/state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-create-update-checklist',
  templateUrl: './create-update-checklist.component.html',
  styleUrls: ['./create-update-checklist.component.scss'],
})
export class CreateUpdateChecklistComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('addChecklistModal', { read: TemplateRef })
  public addChecklistModal: TemplateRef<any>;
  modalRef: ModalRef;
  modalTypes: PropertyBag = ConfigurationConstants.modalType;
  modalType: string;

  types: any = ConfigurationConstants.checklistParameterTypes.types;
  checklistParameterTypes: any = ChecklistParameterTypes;
  isLoading: boolean = true;
  isConfirmCloseModalOpen: boolean = false;
  isConfirmDeleteModalOpen: boolean = false;

  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;

  urlToGetConfigData: string;
  linkToNavigate: string;
  httpCustomErrorCode: any;
  isSubmitting: Observable<boolean>;
  checklistItems: Partial<FormArray>;

  checklistForm: FormGroup = new FormGroup({});

  formBuilder: FormBuilder = inject(FormBuilder);
  dropdownService: DropdownService = inject(DropdownService);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  checklistActions: ChecklistActions = inject(ChecklistActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  private readonly modalService: ModalService = inject(ModalService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  destroyRef: DestroyRef = inject(DestroyRef);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<ApplicationState> = inject(Store<ApplicationState>);

  constructor() {
    super();

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });

    this.checklistForm = this.formBuilder.group({
      name: new FormControl(null),
      description: new FormControl(null),
      items: this.formBuilder.array([]),
      version: new FormControl(null),
    });
  }

  canDeactivate(): boolean {
    if (this.checklistForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  confirmDeleteModalOpen(): void {
    this.isConfirmDeleteModalOpen = true;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  navigateBackOpen(): void {
    this.checklistForm.markAsPristine();
    this.router.navigate([ConfigurationModuleRoutes.configuration]);
  }

  navigateBackClosed(): void {
    this.isConfirmCloseModalOpen = false;
  }

  ngOnInit(): void {
    if (this.router.url.includes(`${this.modalTypes.ADD}-checklist`)) {
      this.modalType = this.modalTypes.ADD;

      this.addParameter();

      this.urlToGetConfigData = this.router.url.slice(22, this.router.url.lastIndexOf('/') - 14);
    }

    this.isSubmitting = combineLatest([
      this.store.select(selectedNewChecklist),
      this.store.select(selectedEditedChecklist),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([newChecklist, editedChecklist]: [
          newChecklist: IStoreApiItem<IChecklist>,
          editedChecklist: IStoreApiItem<IChecklist>,
        ]) => newChecklist.isLoading || editedChecklist.isLoading,
      ),
    );
  }

  ngAfterViewInit(): void {
    if (this.router.url.includes(`${this.modalTypes.UPDATE}-checklist`)) {
      this.modalType = this.modalTypes.UPDATE;

      this.urlToGetConfigData = this.router.url.slice(22, this.router.url.lastIndexOf('/') - 16);
    }

    this.openAddChecklistModal();

    this.store
      .pipe(select(selectedEquipmentConfiguration), takeUntil(this.destroy))
      .subscribe((response: IStoreApiItem<IEquipmentConfiguration>) => {
        if (response.data) {
          this.isLoading = false;
        } else {
          this.router.navigate([ConfigurationModuleRoutes.configuration]);
        }
      });
  }

  items(): FormArray {
    return this.checklistForm.get('items') as FormArray;
  }

  openAddChecklistModal(): void {
    this.modalRef = this.modalService.open(this.addChecklistModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      interactiveBackdrop: false,
      id: ConfigurationConstants.createUpdateChecklistModalId,
      data: 'isOpen',
      dismissOnNavigation: false,
    });

    this.isLoading = false;

    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );
  }

  closeModal(): void {
    if (this.checklistForm.dirty) {
      this.confirmCloseModalOpen();
    } else {
      this.checklistForm.markAsPristine();

      this.modalRef.close('');

      this.router.navigate([ConfigurationModuleRoutes.configuration]);
      Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    }

    sessionStorage.removeItem(ConfigurationConstants.selectedChecklist);
  }

  onSubmit(): void {
    this.checklistForm
      .get('version')
      .setValue(Number(sessionStorage.getItem(ConfigurationConstants.configurationVersion)));
    const objToSend: IChecklist = this.processingChecklistData();

    if (this.modalType === this.modalTypes.ADD) {
      this.checklistActions.requestAddChecklist(objToSend);
      this.handleAddingChecklistResponse();

      return;
    }

    this.checklistActions.requestEditChecklist(objToSend);
    this.handleEditingChecklistResponse();
  }

  processingChecklistData(): IChecklist {
    const objToSend: IChecklist = this.checklistForm.getRawValue() as IChecklist;

    const taskId: string = sessionStorage.getItem(ConfigurationConstants.taskId);
    const taskUrl: string = sessionStorage.getItem(ConfigurationConstants.taskUrl);

    objToSend.aggregateId = taskId;
    objToSend.urlPath = `${taskUrl}/service-definition/${taskId}/checklist`;

    objToSend.items.forEach((item: any) => {
      if (item.type === this.checklistParameterTypes.MULTIPLE_SELECTION_TYPE) {
        item.itemValue = {
          type: item.type,
          options: item.options,
        };

        delete item.options;
      } else {
        delete item.options;

        item.itemValue = {
          type: item.type,
        };
      }

      if (item.type === this.checklistParameterTypes.NUMERIC_TYPE) {
        item.itemValue = {
          type: item.type,
          unit: item.unit,
          minimum: prepareNumericValueData(item.minimumValue),
          maximum: prepareNumericValueData(item.maximumValue),
        };

        delete item.unit;
        delete item.minimumValue;
        delete item.maximumValue;
      }
    });

    return objToSend;
  }

  handleAddingChecklistResponse(): void {
    this.store
      .pipe(
        select(selectedNewChecklist),
        filter((newChecklist: IStoreApiItem<IChecklist>) => !newChecklist.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IChecklist>) =>
        this.handleChecklistActions(response, 'ADD_CHECKLIST_SUCCESS'),
      );
  }

  handleEditingChecklistResponse(): void {
    this.store
      .pipe(
        select(selectedEditedChecklist),
        filter((editedChecklist: IStoreApiItem<IChecklist>) => !editedChecklist.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IChecklist>) =>
        this.handleChecklistActions(response, 'EDIT_CHECKLIST_SUCCESS'),
      );
  }

  handleChecklistActions(response: any, notification: string): void {
    if (response.isSuccess) {
      this.checklistActionSuccess(notification);

      return;
    }

    if (response.errors) {
      this.checklistActionError(response);
    }
  }

  checklistActionSuccess(notification: string) {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.SUCCESS,
      ConfigurationNotificationConstants.notificationCodes[notification],
      ConfigurationNotificationConstants.notificationCodes,
    );

    this.checklistForm.markAsPristine();
    this.closeModal();
    this.refreshServiceDefinition();
    this.isConfirmDeleteModalOpen = false;
  }

  checklistActionError(response: any): void {
    this.httpCustomErrorCode = response.errors?.error.code?.toString();
    let notificationType: string = CommonConstants.notificationType.ERROR;
    const violatedFields: [] = response.errors?.error.violations;

    setTimeout(() => {
      if (this.httpCustomErrorCode === ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST.value) {
        this.refreshServiceDefinition();

        this.checklistForm.markAsPristine();

        this.closeModal();
      } else if (
        this.httpCustomErrorCode ===
        ConfigurationNotificationConstants.notificationCodes.ADDING_CHECKLIST_NUMERIC_TYPE_ERROR.value
      ) {
        notificationType = CommonConstants.notificationType.HIDDEN;
      } else if (violatedFields && violatedFields.length > 0) {
        notificationType = CommonConstants.notificationType.HIDDEN;
        this.httpCustomErrorCode = '400';
      }

      this.callChecklistErrorNotification(
        this.httpCustomErrorCode,
        ConfigurationNotificationConstants.notificationCodes,
        notificationType,
      );
    }, 0);
  }

  callChecklistErrorNotification(
    errorCode: INotificationMessage,
    codes: INotificationConstant,
    notificationType: string = CommonConstants.notificationType.ERROR,
  ): void {
    this.notificationsService.requestShowNotification(notificationType, errorCode, codes);
  }

  onDeleteChecklist(isConfirmed: boolean): void {
    if (!isConfirmed) {
      this.isConfirmDeleteModalOpen = false;

      return;
    }

    const taskId: string = sessionStorage.getItem(ConfigurationConstants.taskId);
    const taskUrl: string = sessionStorage.getItem(ConfigurationConstants.taskUrl);
    const urlToDeleteChecklist: string = `${taskUrl}/service-definition/${taskId}/checklist`;

    this.checklistActions.requestDeleteChecklist({
      urlPath: urlToDeleteChecklist,
      version: +sessionStorage.getItem(ConfigurationConstants.configurationVersion),
    });

    this.store
      .pipe(
        select(selectedDeleteChecklist),
        filter((newService: IStoreApiItem<IChecklist>) => !newService.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IChecklist>) =>
        this.handleChecklistActions(response, 'DELETE_CHECKLIST_SUCCESS'),
      );
  }

  createParameterGroup(type: string) {
    return this.formBuilder.group({
      required: false,
      name: new FormControl(null),
      type: new FormControl(type),
      options: this.formBuilder.array([this.newOption(false)]),
    });
  }

  addParameter(type: string = ChecklistParameterTypes.TEXT_TYPE): void {
    this.items().push(this.createParameterGroup(type));
    this.checklistItems = [];

    this.checklistItems.push(this.createParameterGroup(type));

    this.httpCustomErrorCode = null;

    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );
  }

  newOption(required: boolean): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(null, required ? [Validators.required] : null),
    });
  }

  refreshServiceDefinition(): void {
    this.equipmentConfigActions.requestEquipmentConfiguration({
      urlPath: sessionStorage.getItem(ConfigurationConstants.taskUrl),
    });
  }

  hasUnsavedData(): boolean {
    return this.checklistForm.dirty;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'configuration' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close('');
    }

    sessionStorage.removeItem(ConfigurationConstants.taskUrl);
    sessionStorage.removeItem(ConfigurationConstants.taskId);
  }
}
