import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';
import { catchError, delay, filter, map, Observable, of, switchMap, take, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem, ITableColumn, NotificationsService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import {
  ConfigurationConstants,
  ConfigurationModuleRoutes,
  ConfigurationNotificationConstants,
} from 'src/app/configuration/constants';
import { ICustomTreeNode, IEquipmentConfiguration, ITask } from 'src/app/configuration/models';
import { EquipmentConfigActions, TasksActions } from 'src/app/configuration/state/actions';
import {
  selectDeletedTask,
  selectedEquipmentConfiguration,
} from 'src/app/configuration/state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-tasks-section',
  templateUrl: './tasks-section.component.html',
  styleUrl: './tasks-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSectionComponent extends OnDestroyMixin() implements OnChanges {
  @Input() configurationForm: FormGroup;
  @Input() selectedConfigurationItem: IEquipmentConfiguration;
  @Input() selectedTreeNode: ICustomTreeNode<undefined>;
  @Input() createMode: boolean;
  @Input() equipmentConfigType: string;
  @Input() httpCustomErrorCode: string;
  @Input() itemUrl: string;

  selectedTask: ITask;
  tasks: ITask[];
  openConfirmationDeleteDialog: boolean;
  includedTasks: ITask[] = [];
  selectedIncludedTasks: Partial<ITask[]> = [];
  initialSelectedIncludedTasks: string[] = [];
  itemIsExpandend: boolean;
  currentAction: string | null = null;

  tasksIncludedColumns: ITableColumn[] = [
    { field: 'taskName', header: 'CONFIGURATION.STR_NAME_TABLE_HEADER', visible: true },
    { field: 'displayedDuration', header: 'CONFIGURATION.STR_INTERVAL_DURATION_TABLE_HEADER', visible: true },
  ];

  router: Router = inject(Router);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  storage: StorageMap = inject(StorageMap);
  tasksActions: TasksActions = inject(TasksActions);
  translateService: TranslateService = inject(TranslateService);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(): void {
    if (this.selectedConfigurationItem) {
      this.tasks = structuredClone(this.selectedConfigurationItem.serviceDefinitions);

      if (!this.itemIsExpandend) {
        this.setTasks();
      }
    }
  }

  setTasks(): void {
    this.tasks
      .filter((task: ITask) => !task.isInherited)
      .forEach((task: ITask) => {
        const updatedTask: ITask = { ...task };

        if (updatedTask.interval?.pattern) {
          const pattern: string = this.getPatternTranslation(updatedTask.interval.pattern);

          updatedTask.displayedDuration = `${updatedTask.interval.duration} ${pattern}`;
        } else {
          updatedTask.displayedDuration = ``;
        }

        updatedTask.aggregateId = updatedTask.serviceId;
      });

    this.cdr.detectChanges();
  }

  processSelectedTask(selectedTask: ITask, event: boolean): void {
    this.itemIsExpandend = event;

    sessionStorage.setItem(ConfigurationConstants.taskId, selectedTask.serviceId);

    if (this.itemIsExpandend) {
      this.tasks.forEach((task: ITask) => {
        if (!task.isInherited) {
          this.includedTasks = this.tasks.filter((otherTask: ITask) => otherTask.serviceId !== selectedTask.serviceId);
        }

        Object.freeze(task);
      });

      const copyOfIncludedTasks: ITask[] = structuredClone(this.includedTasks);

      copyOfIncludedTasks.forEach((includedTask: ITask) => {
        includedTask.taskName = this.renameTaskWhenInherited(includedTask);

        if (includedTask.interval?.pattern) {
          const pattern: string = this.getPatternTranslation(includedTask.interval.pattern);

          includedTask.displayedDuration = `${includedTask.interval.duration} ${pattern}`;
        } else {
          includedTask.displayedDuration = ``;
        }
      });

      this.includedTasks = copyOfIncludedTasks;

      const childrenIds: string[] = selectedTask.children.map((children: any) => children.serviceId);

      const localSelected: Partial<ITask[]> = [];

      this.includedTasks.some((task: ITask) => {
        if (childrenIds.includes(task.serviceId)) {
          localSelected.push({
            ...task,
          });
        }
      });

      this.selectedIncludedTasks = [...localSelected];
      this.initialSelectedIncludedTasks = this.getSelectedIds(localSelected);

      this.cdr.detectChanges();
    }
  }

  onIncludedTaskSelect(tasks: Partial<ITask[]>): void {
    this.selectedIncludedTasks = [...tasks];

    if (!isEqual(this.initialSelectedIncludedTasks, this.getSelectedIds(tasks))) {
      this.configurationForm.get('childrenIds').setValue(this.getSelectedIds(tasks));

      if (!this.configurationForm.dirty) {
        this.configurationForm.get('childrenIds').markAsDirty();
      }
    }
  }

  getSelectedIds(tasks: Partial<ITask[]>): string[] {
    return tasks.map((task: Partial<ITask>) => task.serviceId);
  }

  getPatternTranslation(pattern: string): string {
    return this.translateService.instant(
      ConfigurationConstants.intervalList.patterns.find((interval: any) => interval.value === pattern)?.localizedName,
    );
  }

  renameTaskWhenInherited(task: Partial<ITask>): string {
    if (task.isInherited) {
      const inheritedFromText: string = `${this.translateService.instant('CONFIGURATION.STR_INHERITED')} ${task.inheritedFrom ? task.inheritedFrom : ''}`;

      if (task.taskName?.includes(`(${inheritedFromText})`)) {
        return task.taskName;
      }

      return `${task.taskName} (${inheritedFromText})`;
    }

    return task.taskName;
  }

  handleDirtyForm(action: string): Observable<void> {
    this.currentAction = action;

    if (this.configurationForm.dirty) {
      return this.storage.set(ConfigurationConstants.silentSaving, true)?.pipe(map(() => {}));
    }

    return of();
  }

  observeSilentSaving(action: string): Observable<void> {
    return this.storage.watch(ConfigurationConstants.silentSaving).pipe(
      filter((silentSavingResponse: boolean) => silentSavingResponse === false),
      delay(1000),
      filter(() => this.currentAction === action),
      switchMap(() =>
        this.store.pipe(
          select(selectedEquipmentConfiguration),
          filter(
            (storeResponse: IStoreApiItem<IEquipmentConfiguration>) =>
              storeResponse.data !== null && !storeResponse.isLoading,
          ),
          take(1),
          map(() => {}),
        ),
      ),
    );
  }

  finalizeAction(): void {
    this.storage.delete(ConfigurationConstants.silentSaving)?.subscribe(() => {
      return;
    });

    this.currentAction = null;
    this.cdr.detectChanges();
  }

  performNavigation(route: string[], task?: ITask): void {
    sessionStorage.setItem(ConfigurationConstants.taskUrl, this.itemUrl);

    if (task) {
      sessionStorage.setItem(ConfigurationConstants.taskId, task.serviceId);

      if (task.checklist) {
        sessionStorage.setItem(ConfigurationConstants.selectedChecklist, JSON.stringify(task.checklist));
      }
    }

    this.router?.navigate(route);
  }

  addTask(): void {
    const action: string = 'addTask';

    if (!this.configurationForm.dirty) {
      this.performNavigation([ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.createTask]);

      return;
    }

    this.handleDirtyForm(action)
      .pipe(
        switchMap(() => this.observeSilentSaving(action)),
        catchError(() => of()),
      )
      .subscribe(() => {
        this.performNavigation([ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.createTask]);
        this.finalizeAction();
      });
  }

  editTask(task: ITask, event: Event): void {
    event.stopPropagation();
    const action: string = 'editTask';

    if (!this.configurationForm.dirty) {
      this.performNavigation(
        [ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.updateTask, task.serviceId],
        task,
      );

      return;
    }

    this.handleDirtyForm(action)
      .pipe(
        switchMap(() => this.observeSilentSaving(action)),
        catchError(() => of()),
      )
      .subscribe(() => {
        this.performNavigation(
          [ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.updateTask, task.serviceId],
          task,
        );
        this.finalizeAction();
      });
  }

  deleteTask(task: ITask, event: Event): void {
    event.stopPropagation();
    const action: string = 'deleteTask';

    this.selectedTask = task;

    if (!this.configurationForm.dirty) {
      this.openConfirmationDeleteDialog = true;

      return;
    }

    this.handleDirtyForm(action)
      .pipe(
        switchMap(() => this.observeSilentSaving(action)),
        catchError(() => of()),
      )
      .subscribe(() => {
        this.openConfirmationDeleteDialog = true;
        this.finalizeAction();
      });
  }

  addChecklist(task: ITask, event: Event): void {
    event.stopPropagation();
    const action: string = 'addChecklist';

    if (!this.configurationForm.dirty) {
      this.performNavigation(
        [ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.createChecklist, task.serviceId],
        task,
      );

      return;
    }

    this.handleDirtyForm(action)
      .pipe(
        switchMap(() => this.observeSilentSaving(action)),
        catchError(() => of()),
      )
      .subscribe(() => {
        this.performNavigation(
          [ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.createChecklist, task.serviceId],
          task,
        );
        this.finalizeAction();
      });
  }

  editChecklist(task: ITask, event: Event): void {
    event.stopPropagation();
    const action: string = 'editChecklist';

    if (!this.configurationForm.dirty) {
      this.performNavigation(
        [ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.updateChecklist, task.serviceId],
        task,
      );

      return;
    }

    this.handleDirtyForm(action)
      .pipe(
        switchMap(() => this.observeSilentSaving(action)),
        catchError(() => of()),
      )
      .subscribe(() => {
        this.performNavigation(
          [ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.updateChecklist, task.serviceId],
          task,
        );
        this.finalizeAction();
      });
  }

  handleTaskDeletion(): void {
    if (!this.selectedTask.isInherited) {
      const urlPath: string = this.itemUrl;
      const deleteUrl: string = urlPath.concat(`/service-definition/${this.selectedTask.serviceId}`);

      this.tasksActions.requestDeleteTask({
        urlPath: deleteUrl,
        version: +this.configurationForm?.get('version').value,
      });

      this.handleDeletionAction();
    }
  }

  closeDeleteDialog(): void {
    this.openConfirmationDeleteDialog = false;
  }

  handleDeletionAction(): void {
    this.store
      .pipe(
        select(selectDeletedTask),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        const touchedControls: boolean[] = [];

        Object.keys(this.configurationForm.controls).forEach((key: string) => {
          touchedControls.push(this.configurationForm.get(key).touched);
        });

        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_SERVICE_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          this.equipmentConfigActions.requestEquipmentConfiguration({
            urlPath: this.itemUrl,
          });
        } else {
          this.httpCustomErrorCode = response.errors?.error?.code.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            ConfigurationNotificationConstants.notificationCodes,
          );
        }

        if (touchedControls.every((elem: boolean) => elem === false)) {
          this.configurationForm.markAsPristine();
        }

        this.cdr.detectChanges();
      });
  }
}
