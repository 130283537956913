@if (!createMode) {
  <odx-accordion multiple="true">
    <odx-accordion-item>
      <odx-accordion-item-title>
        <div odxLayout="flex gap-small">
          <span class="tasks-label">
            {{ 'CONFIGURATION.STR_TASKS' | translate }}
          </span>
        </div>
      </odx-accordion-item-title>

      <button odxButton variant="secondary" class="mb-5" (click)="addTask()" data-test-id="configuration.add_task_btn">
        <odx-icon name="add" alignLeft />
        {{ 'CONFIGURATION.STR_ADD_TASK_BTN' | translate }}
      </button>

      <odx-accordion multiple="false">
        @for (task of tasks; track task.serviceId; let i = $index) {
          <odx-accordion-item [disabled]="task.inheritedFrom" (expandedChange)="processSelectedTask(task, $event)">
            <odx-accordion-item-title class="position-relative">
              <section odxLayout="grid">
                <div odxLayout="10">
                  <div class="task-header">
                    <span [title]="task.taskName">{{ task.taskName }}</span>
                    @if (task.inheritedFrom) {
                      <odx-chip
                        [removable]="false"
                        size="small"
                        variant="secondary"
                        class="inherited-label"
                        title="{{ 'CONFIGURATION.STR_INHERITED' | translate }} {{ task.inheritedFrom }}"
                      >
                        {{ 'CONFIGURATION.STR_INHERITED' | translate }} {{ task.inheritedFrom }}
                      </odx-chip>
                    }
                  </div>

                  <p class="task-description" [title]="task.description">{{ task.description }}</p>
                </div>

                <div odxLayout="2">
                  <odx-action-group class="task-button-group">
                    <button
                      odxButton
                      variant="secondary"
                      size="small"
                      [disabled]="task.inheritedFrom"
                      (click)="task.checklist ? editChecklist(task, $event) : addChecklist(task, $event)"
                      data-test-id="configuration.add_checklist_hierarchy_btn"
                      [pTooltip]="checklistButtonTooltipContent"
                      tooltipPosition="top"
                      positionTop="-10"
                      positionLeft="-5"
                    >
                      <ng-template #checklistButtonTooltipContent>
                        @if (!task.checklist) {
                          <div data-test-id="configuration_checklist.add_checklist_hierarchy_tooltip">
                            {{ 'CONFIGURATION.STR_ADD_CHECKLIST_TOOLTIP' | translate }}
                          </div>
                        } @else {
                          <div data-test-id="configuration_checklist.edit_checklist_hierarchy_tooltip">
                            {{ 'CONFIGURATION.STR_EDIT_CHECKLIST_TOOLTIP' | translate }}
                          </div>
                        }
                      </ng-template>

                      <odx-icon [name]="task.checklist ? 'test-edit' : 'test'"></odx-icon>
                    </button>
                    <button
                      odxButton
                      variant="secondary"
                      size="small"
                      [disabled]="task.inheritedFrom"
                      (click)="editTask(task, $event)"
                      data-test-id="configuration.edit_task_btn"
                      [pTooltip]="editButtonTooltipContent"
                      tooltipPosition="top"
                      positionLeft="-5"
                      positionTop="-10"
                    >
                      <ng-template #editButtonTooltipContent>
                        <div data-test-id="configuration.edit_task_tooltip">
                          {{ 'CONFIGURATION.STR_EDIT_TASK_TOOLTIP' | translate }}
                        </div>
                      </ng-template>
                      <odx-icon name="edit"></odx-icon>
                    </button>
                    <button
                      odxButton
                      variant="secondary"
                      size="small"
                      [disabled]="task.inheritedFrom"
                      (click)="deleteTask(task, $event)"
                      data-test-id="configuration.delete_task_btn"
                      [pTooltip]="deleteButtonTooltipContent"
                      tooltipPosition="top"
                      positionLeft="-5"
                      positionTop="-10"
                    >
                      <ng-template #deleteButtonTooltipContent>
                        <div data-test-id="configuration.delete_task_tooltip">
                          {{ 'CONFIGURATION.STR_DELETE_TASK_TOOLTIP' | translate }}
                        </div>
                      </ng-template>
                      <odx-icon name="delete"></odx-icon>
                    </button>
                  </odx-action-group>
                </div>
              </section>
            </odx-accordion-item-title>

            @if (!task.isInherited) {
              <div>
                <ignis-table
                  [tableColumns]="tasksIncludedColumns"
                  [dataTable]="includedTasks"
                  [multiSelect]="true"
                  [resetPagining]="false"
                  [customColumnsLayout]="['taskName']"
                  [customColumnsRef]="[taskName]"
                  [preselectedElements]="selectedIncludedTasks"
                  class="tasks-table service-intervals-table"
                  (selectedElementsChange)="onIncludedTaskSelect($event)"
                >
                  <ng-template #taskName let-taskName>
                    <div *ngIf="taskName.field === 'taskName' && taskName.data">
                      <ignis-table-ellipsis-data-column
                        [columnData]="taskName"
                        class="ellipsis-data-column"
                      ></ignis-table-ellipsis-data-column>
                    </div>
                  </ng-template>
                </ignis-table>
              </div>
            }
          </odx-accordion-item>
        }
      </odx-accordion>
    </odx-accordion-item>
  </odx-accordion>

  <ignis-delete-confirmation-modal
    [isOpen]="openConfirmationDeleteDialog"
    (handleDeleteModal)="$event ? handleTaskDeletion() : closeDeleteDialog()"
  >
    <div slot="header" *ngIf="!selectedTask?.isInherited">
      {{ 'CONFIGURATION.STR_DELETE_TASK' | translate }}
    </div>

    <div slot="content" *ngIf="!selectedTask?.isInherited">
      {{ 'CONFIGURATION.STR_DELETE_TASK_CONTENT' | translate }}
    </div>
  </ignis-delete-confirmation-modal>
}
