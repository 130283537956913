import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getDateFromAPIFormat, getDateWithAPIFormat } from '../../utils/date-utils/date.utils';

@Injectable()
export class HttpHelper {
  get options(): HttpHeaderResponse {
    return new HttpHeaderResponse({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  get RAMConnectorOptions(): HttpHeaderResponse {
    return new HttpHeaderResponse({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Frame-Options': 'DENY',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer',
        'Content-Security-Policy': `default-src 'self'; frame-ancestors 'self'`,
      }),
    });
  }

  prepareHttpBody(body: any): any {
    const payload: any = structuredClone(body);

    Object.entries(body).forEach(([key, value]: any): any => {
      if (value instanceof Date) {
        payload[key] = getDateWithAPIFormat(value);
      } else if (Array.isArray(value)) {
        payload[key] = value.map(this.prepareHttpBody);
      } else if (value instanceof Object) {
        payload[key] = this.prepareHttpBody(value);
      } else {
        payload[key] = value;
      }
    });

    return payload;
  }

  prepareHttpResponse = (body: any): any => {
    const payload: any = structuredClone(body);

    Object.entries(body).forEach(([key, value]: any): any => {
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
        payload[key] = getDateFromAPIFormat(value);
      } else if (Array.isArray(value)) {
        payload[key] = value.map(this.prepareHttpResponse);
      } else if (value instanceof Object) {
        payload[key] = this.prepareHttpResponse(value);
      } else {
        payload[key] = value;
      }
    });

    return payload;
  };

  optionsWithIfMatch(version: number): HttpHeaderResponse {
    return new HttpHeaderResponse({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': version?.toString(),
      }),
    });
  }
}
