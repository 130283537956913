import { FormControl, Validators } from '@angular/forms';

export const configurationForm: any = {
  name: new FormControl(null, [Validators.required]),
  protectorType: new FormControl(null),
  aggregateId: new FormControl(null),
  typeId: new FormControl(null),
  modelId: new FormControl(null),
  manufacturerAggregateId: new FormControl(null),
  mediaLinks: new FormControl(null),
  childrenIds: new FormControl(null),
  version: new FormControl(null),
};
