import { INotificationConstant } from 'src/app/common';

export class ConfigurationNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    TASK_NAME_SHOULD_BE_UNIQUE: {
      value: '2005',
      text: 'CONFIGURATION_NOTIFICATION.STR_TASK_NAME_SHOULD_BE_UNIQUE',
    },
    ENTITY_NOT_EXIST: {
      value: '2014',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_ENTITY_NOT_EXIST',
    },
    UPDATE_OLDER_VERSION: {
      value: '2015',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_VERSION_CONFLICT',
    },
    PROVIDED_PROTECTOR_SOFTWARE_ERROR: {
      value: '2019',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_PROVIDED_PROTECTOR_SOFTWARE_ERROR',
    },
    HIERARCHY_ELEMENT_USED: {
      value: '2021',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_HIERARCHY_ELEMENT_USED',
    },
    OPERATION_BLOCKED_DURING_THE_MIGRATION_IN_PROGRESS: {
      value: '2055',
      text: 'CONFIGURATION_NOTIFICATION.STR_OPERATION_BLOCKED_DURING_THE_MIGRATION_IN_PROGRESS',
    },
    LOCATION_ENTITY_NOT_EXIST: {
      value: '13002',
      text: 'CONFIGURATION_NOTIFICATION.STR_LOCATION_ENTITY_NOT_EXIST',
    },
    UPDATE_LOCATION_OLDER_VERSION: {
      value: '13003',
      text: 'CONFIGURATION_NOTIFICATION.STR_UPDATE_LOCATION_OLDER_VERSION',
    },
    NAME_SHOULD_BE_UNIQUE_AT_THE_SAME_LEVEL: {
      value: '13004',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_NAME_SHOULD_BE_UNIQUE_AT_THE_SAME_LEVEL',
    },
    CONFIGURATION_IDENTIFIER_SHOULD_BE_UNIQUE: {
      value: '13005',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_IDENTIFIER_SHOULD_BE_UNIQUE',
    },
    PARENT_ID_ISSUE: {
      value: '13007',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_PARENT_ID_ISSUE',
    },
    LOCATION_RESTRICTED_USER: {
      value: '13008',
      text: 'CONFIGURATION_NOTIFICATION.STR_LOCATION_RESTRICTED_USER',
    },
    LOCATION_ALREADY_ASSIGNED: {
      value: '13009',
      text: 'CONFIGURATION_NOTIFICATION.STR_LOCATION_ALREADY_ASSIGNED',
    },
    LOCATION_CREATE_RESTRICTED: {
      value: '13010',
      text: 'CONFIGURATION_NOTIFICATION.STR_LOCATION_CREATE_RESTRICTED',
    },
    LOCATION_CHANGE_RESTRICTED: {
      value: '13012',
      text: 'CONFIGURATION_NOTIFICATION.STR_LOCATION_CHANGE_RESTRICTED',
    },
    LOCATION_PARENT_ASSIGN_TO_OWN_CHILD: {
      value: '13016',
      text: 'CONFIGURATION_NOTIFICATION.STR_LOCATION_PARENT_ASSIGN_TO_OWN_CHILD',
    },

    ADD_HIERARCHY_SUCCESS: {
      value: 'ADD_HIERARCHY_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_ADD_HIERARCHY_SUCCESS',
    },
    EDIT_HIERARCHY_SUCCESS: {
      value: 'EDIT_HIERARCHY_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_EDIT_HIERARCHY_SUCCESS',
    },
    DELETE_HIERARCHY_SUCCESS: {
      value: 'DELETE_HIERARCHY_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_DELETE_HIERARCHY_SUCCESS',
    },
    ADD_SERVICE_SUCCESS: {
      value: 'ADD_SERVICE_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_ADD_SERVICE_SUCCESS',
    },
    ADD_SERVICE_INTERVAL_SUCCESS: {
      value: 'ADD_SERVICE_INTERVAL_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_ADD_SERVICE_INTERVAL_SUCCESS',
    },
    DELETE_SERVICE_SUCCESS: {
      value: 'DELETE_SERVICE_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_DELETE_SERVICE_SUCCESS',
    },
    EDIT_SERVICE_SUCCESS: {
      value: 'EDIT_SERVICE_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_EDIT_SERVICE_SUCCESS',
    },
    DELETE_SERVICE_INTERVAL_SUCCESS: {
      value: 'DELETE_SERVICE_INTERVAL_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_DELETE_SERVICE_INTERVAL_SUCCESS',
    },
    EDIT_SERVICE_INTERVAL_SUCCESS: {
      value: 'EDIT_SERVICE_INTERVAL_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_EDIT_SERVICE_INTERVAL_SUCCESS',
    },
    DELETE_LOCATION_HIERARCHY_SUCCESS: {
      value: 'DELETE_LOCATION_HIERARCHY_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_DELETE_LOCATION_HIERARCHY_SUCCESS',
    },

    ADD_CHECKLIST_SUCCESS: {
      value: 'ADD_CHECKLIST_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CHECKLIST_SAVED_SUCCESS',
    },
    ADDING_AGAIN_CHECKLIST: {
      value: '2029',
      text: 'CONFIGURATION_NOTIFICATION.STR_ADDING_AGAIN_CHECKLIST_ERROR',
    },
    ADDING_CHECKLIST_NUMERIC_TYPE_ERROR: {
      value: '2030',
      text: 'CONFIGURATION_NOTIFICATION.STR_ADDING_CHECKLIST_NUMERIC_TYPE_ERROR',
    },
    EDIT_DELETED_CHECKLIST: {
      value: '2031',
      text: 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_ENTITY_NOT_EXIST',
    },
    CIRCULAR_INCLUSION: {
      value: '2036',
      text: 'CONFIGURATION_NOTIFICATION.STR_CIRCULAR_INCLUSION_ERROR',
    },
    INVALID_INCLUDED_SERVICES: {
      value: '2037',
      text: 'CONFIGURATION_NOTIFICATION.STR_INVALID_INCLUDED_SERVICES_ERROR',
    },
    INTERVAL_PATTERN_MANDATORY: {
      value: '2038',
      text: 'CONFIGURATION_NOTIFICATION.STR_INTERVAL_PATTERN_MANDATORY_ERROR',
    },
    EDIT_CHECKLIST_SUCCESS: {
      value: 'EDIT_CHECKLIST_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CHECKLIST_UPDATED_SUCCESS',
    },
    DELETE_CHECKLIST_SUCCESS: {
      value: 'DELETE_CHECKLIST_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_CHECKLIST_DELETED_SUCCESS',
    },
    ADD_ADDRESS_BOOK_SUCCESS: {
      value: 'ADD_ADDRESS_BOOK_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_ADD_ADDRESS_BOOK_SUCCESS',
    },
    EDIT_ADDRESS_BOOK_SUCCESS: {
      value: 'EDIT_ADDRESS_BOOK_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_EDIT_ADDRESS_BOOK_SUCCESS',
    },
    DELETE_ADDRESS_BOOK_SUCCESS: {
      value: 'DELETE_ADDRESS_BOOK_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_DELETE_ADDRESS_BOOK_SUCCESS',
    },
    SEARCHED_STATIC_LOCATION: {
      value: '13015',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SEARCHED_STATIC_LOCATION',
    },
    SEARCHED_LOCATION_NOT_DIRECTLY_ASSIGNED: {
      value: 'SEARCHED_LOCATION_NOT_DIRECTLY_ASSIGNED',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SEARCHED_LOCATION_NOT_DIRECTLY_ASSIGNED',
    },
    SEARCHED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION: {
      value: 'SEARCHED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SEARCHED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION',
    },
    EQUIPMENTS_WITHOUT_CYLINDER_TEST_TYPE: {
      value: 'EQUIPMENTS_WITHOUT_CYLINDER_TEST_TYPE',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENTS_WITHOUT_CYLINDER_TEST_TYPE',
    },
    WORKFLOW_FOR_ONLY_ONE_EQUIPMENT: {
      value: 'WORKFLOW_FOR_ONLY_ONE_EQUIPMENT',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SCAN_WORKFLOW_FOR_ONLY_ONE_EQUIPMENT',
    },
    EQUIPMENTS_CHANGE_LOCATION_SUCCESSFULLY: {
      value: 'EQUIPMENTS_CHANGE_LOCATION_SUCCESSFULLY',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SCAN_EQUIPMENTS_CHANGE_LOCATION_SUCCESSFULLY',
    },
    MOBILE_LOCATION_MOVED_SUCCESSFULLY: {
      value: 'MOBILE_LOCATION_MOVED_SUCCESSFULLY',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SCAN_MOBILE_LOCATION_MOVE_SUCCESSFULLY',
    },
    MOBILE_LOCATION_MOVED_SUCCESSFULLY_WITH_SELECTED_WORKFLOW: {
      value: 'MOBILE_LOCATION_MOVED_SUCCESSFULLY_WITH_SELECTED_WORKFLOW',
      text: 'CONFIGURATION_NOTIFICATION.STR_MOBILE_LOCATION_MOVED_SUCCESSFULLY_WITH_SELECTED_WORKFLOW',
    },
    NO_WORKFLOW_FOR_SELECTED_EQUIPMENT: {
      value: 'NO_WORKFLOW_FOR_SELECTED_EQUIPMENT',
      text: 'CONFIGURATION_NOTIFICATION.STR_EQUIPMENT_SCAN_NO_WORKFLOW_FOR_SELECTED_EQUIPMENT',
    },
    PROFILE_NOT_FOUND: {
      value: '3002',
      text: 'CONFIGURATION_NOTIFICATION.STR_ORGANIZATION_SETTINGS_PROFILE_NOT_FOUND',
    },
    ORGANIZATION_ID_DOES_NOT_EXIST: {
      value: '3003',
      text: 'CONFIGURATION_NOTIFICATION.STR_ORGANIZATION_DOES_NOT_EXIST',
    },
    ORGANIZATION_PROFILE_OUTDATED_VERSION: {
      value: '3004',
      text: 'CONFIGURATION_NOTIFICATION.STR_ORGANIZATION_PROFILE_OUTDATED_VERSION',
    },
    SETTINGS_SAVE_SUCCESS: {
      value: 'SETTINGS_SAVE_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_SETTINGS_SAVE_SUCCESS',
    },
    ORGANIZATION_SETTINGS_SAVE_SUCCESS: {
      value: 'ORGANIZATION_SETTINGS_SAVE_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_ORGANIZATION_SETTINGS_SAVE_SUCCESS',
    },
    IMPORTED_HIERARCHY_ALREADY_EXISTS: {
      value: '2054',
      text: 'CONFIGURATION_NOTIFICATION.STR_IMPORTED_HIERARCHY_ALREADY_EXISTS',
    },
    IMPORT_HIERARCHY_FAIL: {
      value: 'IMPORT_HIERARCHY_FAIL',
      text: 'CONFIGURATION_NOTIFICATION.STR_IMPORT_HIERARCHY_FAIL',
    },
    IMPORT_HIERARCHY_SUCCESS: {
      value: 'IMPORT_HIERARCHY_SUCCESS',
      text: 'CONFIGURATION_NOTIFICATION.STR_IMPORT_HIERARCHY_SUCCESS',
    },
    UPLOAD_IMAGE_FORMAT_ERROR: {
      value: 'UPLOAD_IMAGE_FORMAT_ERROR',
      text: 'CONFIGURATION_NOTIFICATION.STR_UPLOAD_IMAGE_FORMAT_ERROR',
    },
    UPLOAD_IMAGE_SIZE_ERROR: {
      value: 'UPLOAD_IMAGE_SIZE_ERROR',
      text: 'CONFIGURATION_NOTIFICATION.STR_UPLOAD_IMAGE_SIZE_ERROR',
    },
  };
}
