import { DOCUMENT, Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, HostListener, Inject, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { combineLatest, delay, filter, map, Observable, take, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationNotificationConstants } from '../../constants';
import { ConfigurationConstants } from '../../constants/configuration.constants';
import { IAddressBookPage } from '../../models';
import {
  ICopyNode,
  ICustomTreeNode,
  IEquipmentConfigType,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
} from '../../models/equipment-configuration.models';
import { EquipmentConfigurationService } from '../../services/equipment-configuration.service';
import { AddressBookActions, EquipmentHierarchyActions, ProtectorTypeActions, TasksActions } from '../../state/actions';

import {
  selectAddedEquipmentHierarchy,
  selectAddressBookPage,
  selectDeletedEquipmentHierarchy,
  selectedEquipmentConfiguration,
  selectEditedEquipmentHierarchy,
  selectEquipmentHierarchy,
  selectImportEquipmentHierarchy,
} from './../../state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-equipment-hierarchy',
  templateUrl: './equipment-hierarchy.component.html',
  styleUrls: ['./equipment-hierarchy.component.scss'],
})
export class EquipmentHierarchyComponent extends OnDestroyMixin() implements OnInit {
  hierarchyData: IEquipmentHierarchy[] | any;
  isLoading: Observable<boolean>;
  configurationForm: FormGroup;

  selectedTreeNode: TreeNode | null;
  previousTreeNode!: TreeNode | null;
  pendingTreeNode: TreeNode | null = null;

  disableAddBtn: boolean = false;
  disableEditBtnAndDelete: boolean = true;
  openConfirmationDeleteDialog: boolean = false;
  openConfirmationChangeUnsavedNodeDialog: boolean = false;
  openImportEQHierarchyModal: boolean = false;
  openCreateMode: boolean = false;
  isSavedButtonPressed: boolean = false;
  deletedType: string;
  equipmentConfigType: string;
  configType: IEquipmentConfigType = ConfigurationConstants.EquipmentConfigType;
  itemUrl: string;
  errors: any;
  currentAppTheme: string;
  searchValue: string;
  linkToNavigate: string;
  comesFromCanDeactivateState: boolean;

  displayImportBtn: boolean = true;
  displayTypeLabel: boolean = false;
  displayModelLabel: boolean = false;

  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  tasksActions: TasksActions = inject(TasksActions);
  equipmentHierarchyActions: EquipmentHierarchyActions = inject(EquipmentHierarchyActions);
  protectorTypeActions: ProtectorTypeActions = inject(ProtectorTypeActions);
  addressBookActions: AddressBookActions = inject(AddressBookActions);
  router: Router = inject(Router);
  location: Location = inject(Location);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  equipmentConfigurationService: EquipmentConfigurationService = inject(EquipmentConfigurationService);

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    super();
    this.currentAppTheme = this.document.body.className.split(' ')[1];
    this.hierarchyData = [
      {
        label: this.translateService.instant('CONFIGURATION.STR_EQUIPMENT_HIERARCHY'),
        data: null,
        styleClass: 'hiddenNode',
        children: [],
      },
    ];

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });
  }

  canDeactivate(): boolean {
    if (this.hasUnsavedData()) {
      this.openConfirmationChangeUnsavedNodeDialog = true;
      this.comesFromCanDeactivateState = true;

      return false;
    } else {
      this.openConfirmationChangeUnsavedNodeDialog = false;

      return true;
    }
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnInit(): void {
    this.equipmentHierarchyActions.requestEquipmentHierarchy();
    this.protectorTypeActions.requestProtectorTypeValues();
    this.addressBookActions.requestAddressBook({
      page: 0,
      size: 100,
      sort: 'organizationName,ASC',
      types: 'MANUFACTURER',
    });

    this.isLoading = combineLatest([
      this.store.select(selectEquipmentHierarchy),
      this.store.select(selectImportEquipmentHierarchy),
      this.store.select(selectedEquipmentConfiguration),
      this.store.select(selectAddedEquipmentHierarchy),
      this.store.select(selectEditedEquipmentHierarchy),
      this.store.select(selectAddressBookPage),
    ]).pipe(
      takeUntil(this.destroy),
      delay(0),
      map(
        ([hierarchyState, importHierarchyState, getState, editState, addState, addressState]: [
          IStoreApiList<IEquipmentHierarchy[]>,
          IStoreApiItem<unknown>,
          IStoreApiItem<IEquipmentConfiguration>,
          IStoreApiItem<IEquipmentHierarchy>,
          IStoreApiItem<IEquipmentHierarchy>,
          IStoreApiItem<IAddressBookPage>,
        ]) =>
          hierarchyState.isLoading ||
          importHierarchyState.isLoading ||
          getState.isLoading ||
          editState.isLoading ||
          addState.isLoading ||
          addressState.isLoading,
      ),
    );

    this.store
      .pipe(
        select(selectEquipmentHierarchy),
        filter((state: IStoreApiList<IEquipmentHierarchy[]>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiList<IEquipmentHierarchy[]>) => {
        if (response.data) {
          this.hierarchyData[0].children = structuredClone([...response.data]);

          this.hierarchyData.filter((parentNode: any) => {
            parentNode.children.some((childNode: any) => {
              if (
                ConfigurationConstants.categoryId in sessionStorage &&
                childNode.data.aggregateId === sessionStorage.getItem(ConfigurationConstants.categoryId)
              ) {
                if (childNode.children.length > 0) {
                  childNode.expanded = true;
                  this.toggleTreeHeader();
                }
              }

              childNode.children.some((nephewNode: any) => {
                if (
                  ConfigurationConstants.typeId in sessionStorage &&
                  nephewNode.data.typeId === sessionStorage.getItem(ConfigurationConstants.typeId) &&
                  nephewNode.children?.length > 0
                ) {
                  nephewNode.expanded = true;
                  this.toggleTreeHeader();
                }
              });
            });

            this.toggleTreeHeader();
          });

          sessionStorage.removeItem(ConfigurationConstants.categoryId);
          sessionStorage.removeItem(ConfigurationConstants.typeId);
          sessionStorage.removeItem(ConfigurationConstants.modelId);

          if (this.hierarchyData[0].children.length > 0) {
            this.hierarchyData[0].expanded = true;
            this.displayImportBtn = false;
          } else {
            this.selectedTreeNode = this.hierarchyData[0];
            this.displayImportBtn = true;
          }

          const itemId: string =
            sessionStorage.getItem(ConfigurationConstants.configurationLocation)?.split('/').pop() ||
            this.itemUrl?.split('/').pop();

          if (itemId) {
            const result: ICustomTreeNode<undefined> = this.findNodeByIdAndSelectThem(this.hierarchyData, itemId);

            this.nodeSelect({ node: result, originalEvent: null });
          }
        }
      });
  }

  findNodeByIdAndSelectThem(tree: ICustomTreeNode<undefined>[], id: string): ICustomTreeNode<undefined> | null {
    for (const node of tree) {
      node.parent = parent;

      if (node.data?.modelId === id || node.data?.typeId === id || node.data?.aggregateId === id) {
        node.highlighted = true;

        return node;
      }

      if (node.children?.length) {
        const found: ICustomTreeNode<undefined> | null = this.findNodeByIdAndSelectThem(node.children, id);

        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  setParentReferences(nodes: any[], parent: any = null): void {
    nodes.forEach((node) => {
      node.parent = parent;

      if (node.children) {
        this.setParentReferences(node.children, node);
      }
    });
  }

  handleUnsavedChangesResponse(isConfirmed: boolean): void {
    this.openConfirmationChangeUnsavedNodeDialog = false;

    if (!isConfirmed) {
      this.selectedTreeNode = this.previousTreeNode;
      this.pendingTreeNode = null;
    } else {
      if (this.comesFromCanDeactivateState) {
        this.configurationForm.markAsPristine();

        if (this.linkToNavigate) {
          this.router.navigate([this.linkToNavigate]);
        } else {
          this.location.back();
        }

        return;
      }

      this.selectedTreeNode = this.pendingTreeNode;
      this.previousTreeNode = this.pendingTreeNode;
      this.pendingTreeNode = null;

      this.configurationForm.markAsPristine();
      this.nodeSelect({ node: this.selectedTreeNode, originalEvent: null });
    }
  }

  nodeSelect(event: TreeNodeSelectEvent): void {
    if (this.hasUnsavedData()) {
      this.openConfirmationChangeUnsavedNodeDialog = true;
      this.pendingTreeNode = event.node;

      return;
    } else {
      this.previousTreeNode = event.node;
    }

    this.setParentReferences(this.hierarchyData);

    this.selectedTreeNode = event.node;
    this.disableAddBtn = false;
    this.disableEditBtnAndDelete = false;
    this.openCreateMode = false;

    sessionStorage.removeItem('isCylinderType');

    if (!this.selectedTreeNode) {
      return;
    }

    this.clearHighlights();
    this.markParentNodes(event.node);

    if (event.node?.children?.length > 0 && !event.node?.expanded) {
      event.node.expanded = !event.node.expanded;
    }

    if (!this.selectedTreeNode.data) {
      this.disableEditBtnAndDelete = true;
    }

    if (this.selectedTreeNode?.parent?.parent?.parent?.data === null && this.selectedTreeNode.children?.length < 1) {
      this.disableAddBtn = true;
    }

    if (!this.selectedTreeNode.parent?.data) {
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_CATEGORY');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.CATEGORY;
      this.itemUrl = `categories/${this.selectedTreeNode.data?.aggregateId}`;
    } else if (!this.selectedTreeNode.parent?.parent.data) {
      sessionStorage.setItem('isCylinderType', this.selectedTreeNode.data.isCylinderType);
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_TYPE');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.TYPE;
      this.itemUrl = `categories/${this.selectedTreeNode.parent.data.aggregateId}/types/${this.selectedTreeNode.data.typeId}`;
    } else if (!this.selectedTreeNode.parent?.parent.parent.data) {
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_MODEL');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.MODEL;
      this.itemUrl = `categories/${this.selectedTreeNode.parent.parent.data.aggregateId}/types/${this.selectedTreeNode.parent.data.typeId}/models/${this.selectedTreeNode.data.modelId}`;
    }

    sessionStorage.removeItem(ConfigurationConstants.configurationLocation);
  }

  nodeUnselect(event: TreeNodeSelectEvent): void {
    if (event.node.children?.length > 0) {
      event.node.expanded = !event.node.expanded;
    }

    this.removeSelectedNode();
    this.openCreateMode = false;
    this.equipmentConfigType = null;

    this.configurationForm.reset();
    this.configurationForm.markAsPristine();
  }

  markParentNodes(node: TreeNode): void {
    let currentNode: ICustomTreeNode<undefined> = node.parent;

    while (currentNode) {
      currentNode.highlighted = true;
      currentNode = currentNode.parent;
    }
  }

  clearHighlights(): void {
    const traverseNodes = (nodes: TreeNode[]) => {
      nodes?.forEach((node: ICustomTreeNode<undefined>) => {
        node.highlighted = false;

        if (node.children) {
          traverseNodes(node.children);
        }
      });
    };

    traverseNodes(this.hierarchyData);
  }

  toggleTreeHeader(): void {
    setTimeout(() => {
      const treeNodeElement: Element = document.getElementsByTagName('p-treenode')[0];
      const typeNodeWidth: number = 250;
      const modelNodeWidth: number = 550;

      this.displayTypeLabel = (treeNodeElement as HTMLElement)?.offsetWidth > typeNodeWidth;
      this.displayModelLabel = (treeNodeElement as HTMLElement)?.offsetWidth > modelNodeWidth;
    }, 0);
  }

  removeSelectedNode(): void {
    this.selectedTreeNode = null;
    this.disableAddBtn = false;
    this.clearHighlights();

    this.router.navigate(['configuration']);
  }

  displayFiteredData(event: IEquipmentHierarchy[]): void {
    this.hierarchyData = event;
    this.toggleTreeHeader();

    this.displayImportBtn = false;
  }

  createNewConfiguration(): void {
    this.openCreateMode = true;
  }

  getConfigurationForm(event: FormGroup): void {
    setTimeout(() => {
      this.configurationForm = event;
    }, 0);
  }

  openDeleteEquipmentHierarchyDialog(): void {
    this.openConfirmationDeleteDialog = true;
  }

  closeDeleteEquipmentHierarchyDialog(confirmation: boolean): void {
    if (confirmation) {
      this.configurationForm.markAsPristine();

      this.deleteConfiguration();
    } else {
      this.openConfirmationDeleteDialog = false;
    }
  }

  saveItem(): void {
    this.isSavedButtonPressed = true;
  }

  deleteConfiguration(): void {
    this.equipmentHierarchyActions.requestDeleteEquipmentHierarchy({
      urlPath: this.itemUrl,
      version: Number(sessionStorage.getItem(ConfigurationConstants.configurationVersion)),
    });

    this.store
      .pipe(
        select(selectDeletedEquipmentHierarchy),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            {
              type: this.deletedType,
            },
          );
          this.selectedTreeNode = null;
          this.openCreateMode = false;
          this.equipmentConfigType = null;
          const itemId: string = this.itemUrl?.split('/').pop();

          this.removeNodeFronTree(itemId);

          this.toggleTreeHeader();
        } else {
          this.errors = response.errors.error?.code.toString();

          if (this.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              this.errors,
              ConfigurationNotificationConstants.notificationCodes,
            );
          }
        }
      });
  }

  removeNodeFronTree(typeIdToRemove: string): void {
    const traverseAndRemove = (
      nodes: ICustomTreeNode<undefined>[],
      parent?: ICustomTreeNode<undefined>,
    ): ICustomTreeNode<undefined>[] => {
      return nodes.filter((node: ICustomTreeNode<undefined>) => {
        if (
          node.data?.aggregateId === typeIdToRemove ||
          node.data?.typeId === typeIdToRemove ||
          node.data?.modelId === typeIdToRemove
        ) {
          return false;
        }

        if (node.children?.length) {
          node.children = traverseAndRemove(node.children, node);

          if (node.children.length === 0) {
            node.expanded = false;
          }
        }

        return true;
      });
    };

    this.hierarchyData = traverseAndRemove(this.hierarchyData);
  }

  openImportEquipmentHierarchyModal(): void {
    this.openImportEQHierarchyModal = true;
  }

  closeImportEquipmentHierarchyModal(event: boolean): void {
    this.openImportEQHierarchyModal = event;
  }

  copyNode(): void {
    const copyDataObj: ICopyNode = {
      categoryId: this.selectedTreeNode.data.aggregateId as string,
      typeId: this.selectedTreeNode.data?.typeId as string,
      modelId: this.selectedTreeNode.data?.modelId as string,
      version: Number(sessionStorage.getItem(ConfigurationConstants.configurationVersion)),
    };

    this.equipmentConfigurationService
      .copyNode(copyDataObj)
      .pipe(take(1))
      .subscribe((response: HttpResponse<unknown>) => {
        const url: string = response.headers?.get('Location');
        const regex: RegExp = /\/categories\/.+/;
        const extractedPath: string = regex.exec(url)?.[0] || '';

        this.itemUrl = extractedPath;
        this.equipmentHierarchyActions.requestEquipmentHierarchy();
      });
  }

  hasUnsavedData(): boolean {
    return this.configurationForm?.dirty;
  }
}
