<div
  [ngClass]="{ 'upload-container-with-images': images?.length > 0 }"
  class="upload-container"
  (drop)="onDrop($event)"
  (dragover)="onDragOver($event)"
  (click)="!imageContentIsClicked && !isDownloadInProgress ? fileInput.click() : null"
>
  <div class="image-grid" [odxLoadingSpinner]="isDownloadInProgress" odxLoadingSpinnerAutoColor>
    @if (isImagesGalleryDisplayed) {
      <div class="image-preview" *ngFor="let image of images; let i = index" (click)="disableUploadOnImageClick()">
        <img [src]="image.source" [alt]="image.name" (click)="openImageModal(image.source)" />
        <button
          type="button"
          class="remove-uploaded-img-btn"
          (click)="deleteImage(image?.name, i); disableUploadOnImageClick()"
        >
          <odx-icon name="close" iconSet="core" size="small"></odx-icon>
        </button>
      </div>
    }

    @if (images?.length > 0) {
      <div class="upload-slot" [ngClass]="{ 'hidden-slot': images?.length === 4 }">
        <span class="upload-icon">
          <odx-icon name="file-image" iconSet="core"></odx-icon>
        </span>
      </div>
    } @else {
      <div class="empty-upload-control">
        <odx-icon name="upload" iconSet="core" size="large"></odx-icon>
        <p class="upload-message mt-3">
          {{ 'CONFIGURATION.STR_UPLOAD_IMAGE_MESSAGE' | translate }}
        </p>
        <p class="upload-info mt-3">
          {{ 'CONFIGURATION.STR_UPLOAD_INFO' | translate }}
        </p>
      </div>
    }

    <input #fileInput type="file" (change)="onFileChange($event)" [accept]="acceptedFormats" hidden />
  </div>
</div>

<ignis-image-modal
  [openModal]="isImageModalOpened"
  [image]="selectedImage"
  (handleCloseNoteImageModal)="closeMediaImageModal()"
></ignis-image-modal>

<ignis-delete-confirmation-modal
  [isOpen]="isConfirmDeleteImageModalOpened"
  (handleDeleteModal)="removeUploadedFile($event)"
  class="is-closed-modal"
>
  <div slot="header">
    {{ 'CONFIGURATION.STR_CONFIRM_DELETE_IMAGE_TITLE' | translate }}
  </div>

  <div slot="content">
    {{ 'CONFIGURATION.STR_CONFIRM_DELETE_IMAGE_MESSAGE' | translate }}
  </div>
</ignis-delete-confirmation-modal>
