<div class="odx-app-page__content">
  <div
    class="custom-odx-tab-bar odx-margin-bottom-24"
    *ngIf="
      (router.url?.split('/').length < 4 &&
        !router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy)) ||
      router.url?.includes(
        ConfigurationModuleRoutes.locationHierarchy,
        ConfigurationModuleRoutes.createLocationHierarchy
      ) ||
      router.url?.includes(
        ConfigurationModuleRoutes.locationHierarchy,
        ConfigurationModuleRoutes.updateLocationHierarchy
      ) ||
      router.url?.includes(ConfigurationModuleRoutes.updateTask) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      router.url?.includes(ConfigurationModuleRoutes.updateChecklist)
    "
  >
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div
          (mouseenter)="isNoPermissionTooltipDisplayedForLocation = true"
          (mouseleave)="isNoPermissionTooltipDisplayedForLocation = false"
          id="location-tab-wrap"
          data-test-id="configuration.location_tab_wrap"
          [ngClass]="{
            'tab-is-active': router.url?.includes(ConfigurationModuleRoutes.locationHierarchy),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            (click)="navigateToLocationTab()"
            id="locationTab"
            data-test-id="configuration.location_tab"
            [disabled]="
              !accessControlService.workshopFeatureToggle ||
              !accessControlService.locationFeatureToggle ||
              !accessControlService.checkPermission(appUserPermissionList.configuration) ||
              !accessControlService.checkPermission(appUserPermissionList.equipmentWrite)
            "
            [pTooltip]="
              isNoPermissionTooltipDisplayedForLocation &&
              (!accessControlService.workshopFeatureToggle ||
                !accessControlService.locationFeatureToggle ||
                !accessControlService.checkPermission(appUserPermissionList.configuration) ||
                !accessControlService.checkPermission(appUserPermissionList.equipmentWrite))
                ? locationTooltipContent
                : null
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'INVENTORY.STR_LOCATION' | translate }}

            <ng-template #locationTooltipContent>
              <div data-test-id="configuration.no_permission_for_location">
                {{ 'CONFIGURATION.STR_NO_PERMISSION' | translate }}
              </div>
            </ng-template>
          </button>
        </div>

        <div
          (mouseenter)="isNoPermissionTooltipDisplayedForEquipment = true"
          (mouseleave)="isNoPermissionTooltipDisplayedForEquipment = false"
          id="equipment-tab-wrap"
          data-test-id="configuration.equipment_tab_wrap"
          [ngClass]="{
            'tab-is-active':
              router.url?.includes(ConfigurationModuleRoutes.configuration) &&
              !router.url?.includes(ConfigurationModuleRoutes.locationHierarchy) &&
              !router.url?.includes(ConfigurationModuleRoutes.addressBook) &&
              !router.url?.includes(ConfigurationModuleRoutes.organizationSettings),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            [ngClass]="{
              disabled:
                !accessControlService.workshopFeatureToggle ||
                !accessControlService.checkPermission(appUserPermissionList.equipmentWrite),
            }"
            (click)="navigateToEquipmentTab()"
            id="equipmentHierarchyTab"
            data-test-id="configuration.equipment_tab"
            [disabled]="
              !accessControlService.workshopFeatureToggle ||
              !accessControlService.checkPermission(appUserPermissionList.equipmentWrite)
            "
            [pTooltip]="
              isNoPermissionTooltipDisplayedForEquipment &&
              (!accessControlService.workshopFeatureToggle ||
                !accessControlService.checkPermission(appUserPermissionList.equipmentWrite))
                ? equipmentTooltipContent
                : null
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'CONFIGURATION.STR_EQUIPMENT_TAB' | translate }}

            <ng-template #equipmentTooltipContent>
              <div data-test-id="configuration.no_permission_for_equipment">
                {{ 'CONFIGURATION.STR_NO_PERMISSION' | translate }}
              </div>
            </ng-template>
          </button>
        </div>

        <div
          (mouseenter)="isNoPermissionTooltipDisplayedForAddressBook = true"
          (mouseleave)="isNoPermissionTooltipDisplayedForAddressBook = false"
          id="address-book-tab-wrap"
          data-test-id="configuration.address_book_tab_wrap"
          [ngClass]="{
            'tab-is-active': router.url?.includes(ConfigurationModuleRoutes.addressBook),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            [ngClass]="{
              disabled:
                !accessControlService.workshopFeatureToggle ||
                !accessControlService.checkPermission(appUserPermissionList.configuration) ||
                !accessControlService.checkPermission(appUserPermissionList.equipmentWrite),
            }"
            (click)="navigateToAddressBookTab()"
            id="addressBookTab"
            data-test-id="configuration.address_book_tab"
            [disabled]="
              !accessControlService.workshopFeatureToggle ||
              !accessControlService.checkPermission(appUserPermissionList.configuration) ||
              !accessControlService.checkPermission(appUserPermissionList.equipmentWrite)
            "
            [pTooltip]="
              isNoPermissionTooltipDisplayedForAddressBook &&
              (!accessControlService.workshopFeatureToggle ||
                !accessControlService.checkPermission(appUserPermissionList.configuration) ||
                !accessControlService.checkPermission(appUserPermissionList.equipmentWrite))
                ? addressBookTooltipContent
                : null
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'CONFIGURATION.STR_ADDRESS_BOOK' | translate }}

            <ng-template #addressBookTooltipContent>
              <div data-test-id="configuration.no_permission_for_address_book">
                {{ 'CONFIGURATION.STR_NO_PERMISSION' | translate }}
              </div>
            </ng-template>
          </button>
        </div>

        <div
          (mouseenter)="isNoPermissionTooltipDisplayedForOrgSettings = true"
          (mouseleave)="isNoPermissionTooltipDisplayedForOrgSettings = false"
          id="org-settings-tab-wrap"
          data-test-id="configuration.org_settings_tab_wrap"
          [ngClass]="{
            'tab-is-active': router.url?.includes(ConfigurationModuleRoutes.organizationSettings),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            [ngClass]="{
              disabled:
                !accessControlService.checkPermission(appUserPermissionList.organization) ||
                !accessControlService.checkPermission(appUserPermissionList.configuration),
            }"
            (click)="navigateToOrganizationSettingsTab()"
            id="organizationTab"
            data-test-id="configuration.org_settings_tab"
            [disabled]="
              !accessControlService.checkPermission(appUserPermissionList.organization) ||
              !accessControlService.checkPermission(appUserPermissionList.configuration)
            "
            [pTooltip]="
              isNoPermissionTooltipDisplayedForOrgSettings &&
              (!accessControlService.checkPermission(appUserPermissionList.organization) ||
                !accessControlService.checkPermission(appUserPermissionList.configuration))
                ? orgSettingsTooltipContent
                : null
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'ORGANIZATION_SETTINGS.STR_ORGANIZATION_SETTINGS' | translate }}

            <ng-template #orgSettingsTooltipContent>
              <div data-test-id="configuration.no_permission_for_org_settings">
                {{ 'CONFIGURATION.STR_NO_PERMISSION' | translate }}
              </div>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
